var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout"},[(!_vm.isLoading && (_vm.publisherIsReady || _vm.attendeeIsReady))?_c('div',{staticClass:"meeting-wrapper",class:{ mobile: _vm.isActiveSidebarV }},[_c('div',{staticClass:"meeting-wrapper-l"},[_c('Header',{on:{"changeScreen":_vm.onChangeScreen}}),_c('div',{staticClass:"section-screen"},[(
            (!_vm.isLive && !_vm.isGoingLive || _vm.isLive && _vm.isGoingLive) &&
        _vm.currentUser.role !== 'moderator' &&
        _vm.currentUser.role !== 'publisher' &&
        _vm.currentUser.role !== 'comoderator')?[_vm._m(0)]:[_c('MediaLayout',{attrs:{"sidebar":_vm.isActiveSidebarV}})]],2)],1),_c('div',{staticClass:"meeting-wrapper-r"},[_c('button',{staticClass:"right-collapse",on:{"click":_vm.isActiveSidebar}},[_c('img',{attrs:{"src":require("@/img/nav-arrow.svg")}})]),_c('div',{staticClass:"section-sidebar-nav"},[_c('ul',[_c('li',{class:{ active: _vm.activeTab == 'q&a' }},[_c('button',{on:{"click":function($event){_vm.activeTab = 'q&a'}}},[_vm._v(" Q&A ")])]),_c('li',{class:{ active: _vm.activeTab == 'chat' }},[_c('button',{on:{"click":function($event){_vm.activeTab = 'chat'}}},[_vm._v(" Chat ")]),(_vm.newMessages != 0)?_c('div',{staticClass:"message-badge"},[_vm._v(" "+_vm._s(_vm.newMessages)+" ")]):_vm._e()]),(
              _vm.currentUser.role == 'moderator' ||
                _vm.currentUser.role == 'publisher' ||
                _vm.currentUser.role == 'comoderator'
            )?_c('li',{class:{ active: _vm.activeTab == 'people' },on:{"click":function($event){_vm.activeTab = 'people'}}},[_c('button',[_vm._v("People")])]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'q&a'),expression:"activeTab === 'q&a'"}]},[_c('q-n-a')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'chat'),expression:"activeTab === 'chat'"}]},[_c('Chat',{ref:"chatTab"})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.activeTab === 'people' &&
            (_vm.currentUser.role == 'moderator' ||
              _vm.currentUser.role == 'publisher' ||
              _vm.currentUser.role == 'comoderator')
        ),expression:"\n          activeTab === 'people' &&\n            (currentUser.role == 'moderator' ||\n              currentUser.role == 'publisher' ||\n              currentUser.role == 'comoderator')\n        "}]},[_c('People')],1)])]):_vm._e(),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},on:{"close":function($event){return _vm.$store.commit('user/setSettingsModal', false)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('AVSettings',{on:{"close":props.close}})]}}]),model:{value:(_vm.settingsModalModel),callback:function ($$v) {_vm.settingsModalModel=$$v},expression:"settingsModalModel"}}),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.currentUser.role == 'subscriber')?_c('AttendeeJoinModal',{attrs:{"has-modal-card":"","can-cancel":false,"width":"640"},on:{"close":props.close}}):_vm._e(),(
          _vm.currentUser.role == 'moderator' ||
            _vm.currentUser.role == 'publisher' ||
            _vm.currentUser.role == 'comoderator'
        )?_c('PresenterJoinModal',{attrs:{"has-modal-card":"","can-cancel":false,"width":"640"},on:{"close":props.close}}):_vm._e()]}}]),model:{value:(_vm.isJoinModalActive),callback:function ($$v) {_vm.isJoinModalActive=$$v},expression:"isJoinModalActive"}}),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},on:{"close":function($event){return _vm.$store.commit('user/setBackgroundModal', false)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('BackgroundChanger',{on:{"close":props.close}})]}}]),model:{value:(_vm.backgroundModalModel),callback:function ($$v) {_vm.backgroundModalModel=$$v},expression:"backgroundModalModel"}}),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},on:{"close":function($event){return _vm.$store.commit('user/setLayoutBackgroundModal', false)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('LayoutBackgroundChanger',{on:{"close":props.close}})]}}]),model:{value:(_vm.layoutBackgroundModalModel),callback:function ($$v) {_vm.layoutBackgroundModalModel=$$v},expression:"layoutBackgroundModalModel"}}),(_vm.isGoingLive)?_c('div',{staticClass:"is-going-live"},[_c('div',{staticClass:"going-live-container"},[_c('div',[_c('h3',[_vm._v("Going Live")]),_c('div',{staticClass:"lds-dual-ring",staticStyle:{"display":"block"}},[_c('div',{staticClass:"rd-1"}),_c('div',{staticClass:"rd-2"}),_c('div',{staticClass:"rd-3"}),_c('div',{staticClass:"rd-4"}),_c('div',{staticClass:"rb-1"}),_c('div',{staticClass:"rb-2"}),_c('div',{staticClass:"rb-3"}),_c('div',{staticClass:"rb-4"}),_c('h3',{staticStyle:{"z-index":"4"}},[_vm._v(_vm._s(_vm.countDown))])])])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"help-text"},[_c('div',{staticStyle:{"display":"inline-block"}},[_c('span',{staticStyle:{"display":"block"}},[_vm._v(" Event not started yet ")])])])}]

export { render, staticRenderFns }