<template>
  <!-- store'daki participant array'ini map et -->
  <div class="section-people-wrapper">
    <div class="avatar flex-center" :class="getColor">
      {{ getInitials(participant.name) }}
    </div>
    <div class="name flex-center">{{ getName(participant) }}</div>
    <div
      v-if="
        user.role === 'moderator' ||
          user.role === 'comoderator' ||
          room.connection.connectionId ==
            participant.connection.connectionId
      "
      class="actions flex-center"
    >
      <button
        class="info"
        v-on:click="
          changeAudio(
            participant,
            participant.connection.connectionId,
          )
        "
      >
        <i
          class="bi bi-mic icon"
          :class="[participant.isSpeaking ? 'people-speaking' : '']"
          v-if="participant.hasAudio"
        ></i>
        <i
          class="bi bi-mic-mute-fill icon red"
          v-if="!participant.hasAudio"
        ></i>
      </button>
      <button
        class="videocam"
        v-on:click="
          changeVideo(
            participant,
            participant.connection.connectionId,
          )
        "
      >
        <i
          class="bi bi-camera-video icon"
          v-if="participant.hasVideo"
        ></i>
        <i
          class="bi bi-camera-video-off-fill icon red"
          v-if="!participant.hasVideo"
        ></i>
      </button>
      <b-dropdown
        v-if="
          user.role === 'moderator' || user.role === 'comoderator'
        "
        aria-role="list"
        position="is-bottom-left"
      >
        <button class="button is-text" slot="trigger">
          <span style="font-size: 12px">More</span>
          <i
            class="bi bi-chevron-down"
            style="font-size: 12px; margin-left: 2px"
          ></i>
        </button>

        <b-dropdown-item
          aria-role="listitem"
          v-if="participant.userRole !== 'moderator'"
          v-on:click="
            changeUserRole({
              connectionId: participant.connection.connectionId,
              role: 'moderator',
            })
          "
          >Make Host</b-dropdown-item
        >
        <b-dropdown-item
          aria-role="listitem"
          v-if="participant.userRole !== 'comoderator'"
          v-on:click="
            changeUserRole({
              connectionId: participant.connection.connectionId,
              role: 'comoderator',
            })
          "
          >Make Co-Host</b-dropdown-item
        >
        <b-dropdown-item
          aria-role="listitem"
          v-if="participant.userRole !== 'publisher'"
          v-on:click="
            changeUserRole({
              connectionId: participant.connection.connectionId,
              role: 'publisher',
            })
          "
          >Make Presenter</b-dropdown-item
        >
        <b-dropdown-item
          aria-role="listitem"
          v-if="participant.userRole !== 'subscriber'"
          v-on:click="
            changeUserRole({
              connectionId: participant.connection.connectionId,
              role: 'subscriber',
            })
          "
          >Make Attendee</b-dropdown-item
        >
        <b-dropdown-item
          aria-role="listitem"
          v-on:click="forceDisconnect()"
          >Remove from meeting</b-dropdown-item
        >
      </b-dropdown>
    </div>
  </div>
</template>
<style>
.people-speaking {
  background: -webkit-linear-gradient(90deg, #0b6623, #0b6623 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'PeopleListItem',
  computed: {
    ...mapState('user', { user: (state) => state.current }),
    ...mapState('room', {
      room: (state) => state.current,
      streams: (state) => state.streams,
    }),
    getColor() {
      let colors = ['green', 'purple', 'blue', 'red', 'yellow'];
      let color = colors[Math.floor(Math.random() * colors.length)];

      return color;
    },
  },
  props: ['participant'],
  methods: {
    ...mapActions({
      forceUnpublish: 'room/forceUnpublish',
      forceDisconnect: 'room/forceDisconnect',
      muteConnection: 'room/muteConnection',
      unMuteConnection: 'room/unMuteConnection',
      publishConnection: 'room/publishConnection',
      unPublishConnection: 'room/unPublishConnection',
      changeUserRole: 'room/changeUserRole',
      onMuted: 'user/onMuted',
      onUnMuted: 'user/onUnMuted',
      onPublishVideo: 'user/onPublishVideo',
      onUnPublishVideo: 'user/onUnPublishVideo',
      videoOn: 'user/videoOn',
      videoOff: 'user/videoOff',
      micOn: 'user/micOn',
      micOff: 'user/micOff',
    }),
    ...mapMutations({
      mutePublisher: 'user/onMuted',
      unMutePublisher: 'user/onUnMuted',
    }),
    changeAudio(participant, connectionId) {
      if (participant.hasAudio) {
        if (connectionId === this.room.connection.connectionId) {
          this.micOff();
        } else {
          this.muteConnection(connectionId);
        }
      } else {
        if (connectionId === this.room.connection.connectionId) {
          this.micOn();
        } else {
          this.unMuteConnection(connectionId);
        }
      }
    },
    changeVideo(participant, connectionId) {
      if (participant.hasVideo) {
        if (connectionId === this.room.connection.connectionId) {
          this.videoOff();
        } else {
          this.unPublishConnection(connectionId);
        }
      } else {
        if (connectionId === this.room.connection.connectionId) {
          this.videoOn();
        } else {
          this.publishConnection(connectionId);
        }
      }
    },
    getInitials(name) {
      let parts = name.split(' ');
      let initials = '';

      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
          initials += parts[i][0];
        }
      }
      return initials;
    },
    getName(participant) {
      let name = participant.name;

      if (participant.userRole === 'moderator') {
        name = `${name} (H)`;
      } else if (participant.userRole === 'comoderator') {
        name = `${name} (CH)`;
      } else if (participant.userRole === 'publisher') {
        name = `${name} (P)`;
      }

      if (
        this.room.connection.connectionId ===
        participant.connection.connectionId
      ) {
        name = `${name} (me)`;
      }

      return name;
    },
  },
  created() {
    console.log(this.participant);
  },
};
</script>
