<template>
  <div class="section-miniwrapper">
    <div class="section-chat"
    :class="currentUser.role == 'moderator' ? 'moderator-qna' : 'attendee-qna'">
      <div v-if="currentUser.role == 'moderator'" class="floating-buttons">
        <b-button
            :type="roomType == 'all' ? 'is-info': 'is-text'"
            @click="roomType = 'all'">Inbox</b-button>
        <b-button
            :type="roomType == 'archive' ? 'is-info': 'is-text'"
            @click="roomType = 'archive'">Archive</b-button>
        <b-button
            :type="roomType == 'approved' ? 'is-info': 'is-text'"
            @click="roomType = 'approved'">Approved</b-button>
      </div>
      <div class="scroll-wrapper qna-scroll-wrapper">
        <infinite-loading
          v-if="messages.length > 0"
          class="mb-3"
          direction="top"
          spinner="waveDots"
          :distance="5"
          @infinite="infiniteHandler"
          forceUseInfiniteWrapper=".scroll-wrapper"
        >
          <b-notification
            slot="no-more"
            type="is-success is-light"
            :closable="false"
          >
            No more messages.
          </b-notification>
        </infinite-loading>
        <div
          class="message-item qna-item left"
          v-for="(message, $index) in controlMessages()"
          :key="message.id"
          :data-num="messages.length - $index"
        >
          <div class="qna-left">
            <div class="avatar flex-center" :class="getColor">
              {{ getInitials(message.name) }}
            </div>
            <i @click="approve(message.id)" class="bi bi-check-circle"></i>
          </div>
          <div class="qna-right">
            <div class="header">
              <div class="name">{{ message.name }}</div>
              <time>{{ timeConverter(message.timestamp) }}</time>
            </div>
            <p>{{ message.message }} {{message.timestamp}}</p>

            <div class="mt-2 message-bottom">
              <div class="message-left">
                <div v-if="upvoted" class="cursor-pointer" @click="downvote(message.id)">
                  <i class="bi bi-hand-thumbs-down-fill"></i>
                  Downvote
                </div>
                <div v-else="upvoted" class="cursor-pointer" @click="upvote(message.id)">
                  <i class="bi bi-hand-thumbs-up-fill"></i>
                  Upvote
                </div>
                <div class="ml-2">
                  {{ message.upvote }}
                </div>
              </div>
              <div v-if="currentUser.role == 'moderator'" class="message-right">
                <div @click="() => {replyModal = true; replyId = message.id;}" class="ml-2 cursor-pointer">
                  Reply
                </div>
                <div @click="archive(message.id)" class="ml-2 cursor-pointer">
                  Archive
                </div>
                <div @click="markAsAnswered(message.id)" class="ml-2 cursor-pointer">
                  Mark as answered
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentUser.role != 'moderator'" class="qna-input">
        <form @submit.prevent="sendMessage">
          <b-input v-model="message" maxlength="200" type="textarea"></b-input>
          <button style="background-color: #35db9f; color: white" type="submit">
            Ask
          </button>
        </form>
      </div>
    </div>
    <b-modal
        v-model="replyModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
        @close="replyModal = false"
    >
      <template #default="props">
        <header class="modal-card-head">
          <p class="modal-card-title">Reply</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>

        <section class="modal-card-body reply-area">
          <b-field label="Answer">
            <b-input v-model="messageReply" maxlength="200" type="textarea"></b-input>
          </b-field>
        </section>

        <footer class="modal-card-foot">
          <button @click="reply()" class="button is-primary">Reply</button>
        </footer>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { auth, rtdb } from "../../../services/firebase";
import ErrorService from "../../../services/error-service";
import InfiniteLoading from "vue-infinite-loading";
import moment from "moment";
import BButton from "buefy/src/components/button/Button";

export default {
  name: "QNA",
  data() {
    return {
      messages: [],
      message: "",
      messageReply: "",
      user: auth().currentUser,
      newestId: null,
      isBottom: true,
      roomType: "all",
      replyModal: false,
      replyId: null,
      upvoted: false,
      allMessageCount: 0,
      presenterMessageCount: 0,
    };
  },
  components: {
    BButton,
    InfiniteLoading
  },
  computed: {
    ...mapGetters("user", {
      currentUser: "current"
    }),
    getColor() {
      let colors = ['green', 'purple', 'blue', 'red', 'yellow'];
      let color = colors[Math.floor(Math.random() * colors.length)];

      return color;
    },
  },
  mounted: function() {
    this.initMessages();
    this.scrollToBottom();
    this.scrollListenInit();

    console.log('----', this.currentUser);
    if(this.currentUser.role != 'moderator'){
      this.roomType = 'approved';
    }
  },
  methods: {
    controlMessages(type) {
      if ( this.roomType == "all") {
        return this.messages;
      } else {
        return this.messages.filter(a => a.type == this.roomType);
      }
    },
    getInitials(name) {
      let parts = name.split(' ');
      let initials = '';

      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
          initials += parts[i][0];
        }
      }
      return initials;
    },
    downvote(id) {
      let webcast = this.$route.params.slug;
      const ref = rtdb.ref(`/qna/${webcast}`);
      let postData =  this.messages.find(m => m.id == id);
      postData.upvote--;
      postData.timestamp = Date.now();
      var updates = {};
      updates[`/qna/${webcast}/${id}`] = postData;
      rtdb.ref().update(updates);
      this.upvoted = false;
    },
    upvote(id) {
      let webcast = this.$route.params.slug;
      const ref = rtdb.ref(`/qna/${webcast}`);
      let postData =  this.messages.find(m => m.id == id);
      postData.upvote++;
      postData.timestamp = Date.now();
      var updates = {};
      updates[`/qna/${webcast}/${id}`] = postData;
      rtdb.ref().update(updates);
      this.upvoted = true;
    },
    approve(id) {
      let webcast = this.$route.params.slug;
      const ref = rtdb.ref(`/qna/${webcast}`);
      let postData =  this.messages.find(m => m.id == id);
      postData.type = 'approved';
      postData.timestamp = Date.now();
      var updates = {};
      console.log('---', postData);
      updates[`/qna/${webcast}/${id}`] = postData;
      rtdb.ref().update(updates);
    },
    markAsAnswered(id) {
      let webcast = this.$route.params.slug;
      const ref = rtdb.ref(`/qna/${webcast}`);
      let postData =  this.messages.find(m => m.id == id);
      postData.type = 'answered';
      postData.timestamp = Date.now();
      var updates = {};
      updates[`/qna/${webcast}/${id}`] = postData;
      rtdb.ref().update(updates);
    },
    archive(id) {
      let webcast = this.$route.params.slug;
      const ref = rtdb.ref(`/qna/${webcast}`);
      let postData =  this.messages.find(m => m.id == id);
      postData.type = 'archive';
      postData.timestamp = Date.now();
      var updates = {};
      updates[`/qna/${webcast}/${id}`] = postData;
      rtdb.ref().update(updates);
    },
    reply() {
      let webcast = this.$route.params.slug;
      const ref = rtdb.ref(`/qna/${webcast}`);
      let postData =  this.messages.find(m => m.id == this.replyId);
      postData.answer = this.messageReply;
      postData.timestamp = Date.now();
      var updates = {};
      updates[`/qna/${webcast}/${id}`] = postData;
      rtdb.ref().update(updates);
    },
    fetchOldMessages(callback) {
      let webcast = this.$route.params.slug;
      // deneme chat room'undaki messages koleksiyonunun referansını al
      let ref = rtdb.ref(`qna/${webcast}`);
      let messagesNew = [];
      return ref
        .endAt(this.messages[0].id)
        .orderByKey()
        .limitToLast(10)
        .once("value")
        .then(snapshot => {
          snapshot.forEach(item => {
            const itemData = item.val();
            itemData.id = item.key;
            if (this.messages.findIndex(v => v.id === itemData.id) == -1) {
              messagesNew.push(itemData);
            }
          });
          this.messages = messagesNew.concat(this.messages);
          callback(messagesNew);
        })
        .catch(error => {
          const e = Object.assign(error, {
            message: "Data fetch error"
          });
          throw e;
        });
    },
    initMessages() {
      let webcast = this.$route.params.slug;
      // deneme chat room'undaki messages koleksiyonunun referansını al
      let ref = rtdb.ref(`/qna/${webcast}`);
      let messagesNew = [];
      return ref
        .orderByKey()
        .limitToLast(10)
        .once("value")
        .then(snapshot => {
          snapshot.forEach(item => {
            if( item.key == 'all' || item.key == 'presenter') {

              item.forEach(i => {
                const itemData = i.val();
                itemData.id = i.key;
                itemData.channel = item.key;
                if (this.messages.findIndex(v => v.id === itemData.id) == -1) {
                  messagesNew.push(itemData);
                }
              });
            }
          });
          this.messages = this.messages.concat(messagesNew);
          this.listenAll();
          this.isBottom = false;
          this.scrollToBottom();
        })
        .catch(error => {
          const e = Object.assign(error, {
            message: "Data fetch error"
          });
          throw e;
        });
    },
    listenAll() {
      // TODO get call id
      let webcast = this.$route.params.slug;
      let ref = rtdb.ref(`/qna/${webcast}`).orderByKey();

      if (this.messages.length > 0) {
        ref = ref.startAt(this.messages[this.messages.length - 1].id);
      }

      ref.on(
        "child_changed",
        item => {
          const itemData = item.val();
          this.messages.splice(this.messages.findIndex(m => m.id == itemData.id), 1, itemData);
        },
        err => {
          console.log("----------", err);
        }
      );

      ref.on(
        "child_added",
        item => {
          const itemData = item.val();
          itemData.id = item.key;
          itemData.channel = 'all';
          if (this.messages.findIndex(v => v.id === itemData.id) == -1) {
            this.messages.push(itemData);
            if (this.$parent.activeTab != "chat") {
              this.$store.commit("room/newMessageAdded");
            }
            this.scrollToBottom();
          }
        },
        err => {
          console.log("----------", err);
        }
      );
    },
    sendMessage: async function() {
      try {
        let webcast = this.$route.params.slug;
        const ref = rtdb.ref(`/qna/${webcast}`);
        const { key } = ref.push();

        return ref
          .child(key)
          .update({
            from: auth().currentUser.uid,
            name: this.currentUser.name,
            message: this.message,
            upvote: 0,
            type: 'new',
            timestamp: Date.now()
          })
          .then(() => {
            this.message = "";
          })
          .catch(err => {
            console.log("error", err);
          });
      } catch (error) {
        console.log(error);

        throw ErrorService.onFirebase("Error Sending Message", error);
      }
    },
    async infiniteHandler($state) {
      this.fetchOldMessages(function(messages) {
        if (messages.length) {
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    timeConverter(d) {
      return moment(d).format("HH:mm");
    },
    scrollListenInit() {
      const self = this;
      document
        .querySelector(".scroll-wrapper")
        .addEventListener("scroll", function(e) {
          if (
            e.target.scrollTop + 200 + e.target.clientHeight >
            e.target.scrollHeight
          ) {
            self.isBottom = true;
          } else {
            self.isBottom = false;
          }
        });
    },
    scrollToBottom() {
      if (this.isBottom) {
        setTimeout(function() {
          document.querySelector(
            ".scroll-wrapper"
          ).scrollTop = document.querySelector(".scroll-wrapper").scrollHeight;
        }, 400);
      }
    },
  }
};
</script>

<style lang="scss">

.qna-scroll-wrapper .infinite-status-prompt {
  display: none;
}
.qna-right {
  width: -webkit-fill-available;
}
.floating-buttons {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.reply-area {
  color: white;
  textarea {
    background-color: white;
    color: black;
  }
}
.qna-item {
  width: calc(100% - 8px)!important;
  display: flex;

  .avatar {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    vertical-align: center;

    font-weight: 500;
    font-size: 14.4px;
    line-height: 19px;
    margin: 0px auto;

    &.green {
      background-color: rgba(118, 255, 222, 0.5);
    }

    &.purple {
      background-color: rgba(195, 118, 255, 0.5);
    }

    &.blue {
      background-color: rgba(0, 101, 242, 0.5);
    }

    &.red {
      background-color: rgba(255, 118, 118, 0.5);
    }

    &.yellow {
      background-color: rgba(255, 249, 95, 0.5);
    }
  }

}

.qna-left {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  i {
    color: green;
    margin: 4px auto;
    cursor: pointer;
  }
}

.message-bottom, .message-right, .message-left {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.message-left {
  width: 150px;
}

.message-right {
  flex-flow: row-reverse;
}

.attendee-qna .qna-scroll-wrapper {
  height: calc(100vh - 260px);
}

.moderator-qna .qna-scroll-wrapper {
  height: calc(100vh - 130px);
}

.qna-scroll-wrapper {
  overflow-y: scroll;
}

.message-badge-chat {
  top: -5px!important;
  left: -10px!important;
}

/* width */
.scroll-wrapper::-webkit-scrollbar {
  width: 5px;
  border-radius: 2.5px;
}

/* Track */
.scroll-wrapper::-webkit-scrollbar-track {
  background: #201f1f;
  border-radius: 2.5px;
}

/* Handle */
.scroll-wrapper::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 2.5px;
}

/* Handle on hover */
.scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 2.5px;
}

.qna-input {
  padding-top: 11px;
  padding-right: 8px;
}

.qna-input input {
  width: calc(100% - 70px);
  height: 40px;
  border: 0px !important;
  border-radius: 5px 0px 0px 5px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.09);
  color: #ececec;
}

.qna-input button {
  padding: 5px;
  width: 70px;
  height: 40px;
  border-radius: 5px 5px 5px 5px;
  float: right;
}
</style>
