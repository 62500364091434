<template>
  <div class="modal-card av-settings" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Settings</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>

    <section class="modal-card-body">
      <b-field label="Audio">
        <b-select
          v-model="audioSource"
          placeholder="Change your default Audio Input"
        >
          <option
            v-for="option in audioInputs"
            :value="option.label"
            :key="option.label"
          >
            {{ option.label }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Video">
        <b-select
          v-model="videoSource"
          placeholder="Change your default Video Input"
        >
          <option
            v-for="option in videoInputs"
            :value="option.label"
            :key="option.label"
          >
            {{ option.label }}
          </option>
        </b-select>
      </b-field>
    </section>

    <!--
                    <section class="modal-card-body">
                        <b-field label="Email">
                            <b-input
                                type="email"
                                :value="email"
                                placeholder="Your email"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Password">
                            <b-input
                                type="password"
                                :value="password"
                                password-reveal
                                placeholder="Your password"
                                required>
                            </b-input>
                        </b-field>

                        <b-checkbox>Remember me</b-checkbox>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button" type="button" @click="$emit('close')">Close</button>
                        <button class="button is-primary">Login</button>
                    </footer>
       -->

    <footer class="modal-card-foot">
      <button @click="save()" class="button is-primary">Save</button>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      audioInputs: [],
      videoInputs: [],
      audioSource: null,
      videoSource: null,
    };
  },
  computed: {
    ...mapGetters('user', {
      publisher: 'publisher',
    }),
  },

  async mounted() {
    const self = this;
    if (this.publisher.getAudioSource()) {
      this.audioSource = this.publisher.getAudioSource().label;
    }
    this.getDeviceList(function(data) {
      self.videoInputs = data.videoInputs;
      self.audioInputs = data.audioInputs;
      if (data.videoSource) {
        self.videoSource = data.videoSource.label;
      }
    });
  },
  methods: {
    ...mapActions({
      getDeviceList: 'user/getDeviceList',
    }),
    save() {
      this.publisher.setAudioSource(
        this.audioInputs.find((v) => v.label == this.audioSource)
          .deviceId,
      );
      this.publisher.setVideoSource(
        this.videoInputs.find((v) => v.label == this.videoSource)
          .deviceId,
      );
    },
  },
};
</script>

<style></style>
