import Vue from 'vue';
import Vuex from 'vuex';

import event from './modules/event/';
import user from './modules/user/';
import room from './modules/room/';

Vue.use(Vuex);


const store = new Vuex.Store({
  modules: { event, user, room },
});

export default store;

let initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState () {

  initialStateCopy.user.isRoleChanging = true;
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
}

// export default new Vuex.Store({
//   state: {
//     participants: [],
//     webcast: null,
//     user: null,
//     streams: [],
//     isSharing: false,
//     token: null,
//   },
//   mutations: {
//     setParticipants: (state, payload) => {
//       state.participants = [...payload];
//     },
//     setWebcast: (state, payload) => {
//       state.webcast = payload;
//     },
//     setUser: (state, payload) => {
//       state.user = payload;
//     },
//     setStreams: (state, payload) => {
//       state.streams = [...payload];
//     },
//     setIsSharing: (state) => {
//       state.isSharing = !state.isSharing;
//     },
//     setToken: (state, payload) => {
//       state.token = payload;
//     },
//   },
//   plugins: [],
//   actions: {
//     setParticipants: ({ commit }, participants) => {
//       commit('setParticipants', participants);
//     },
//     setStreams: ({ commit }, streams) => {
//       commit('setStreams', streams);
//     },
//     setWebcast: ({ commit }, webcast) => {
//       commit('setWebcast', webcast);
//     },
//     setUser: ({ commit }, user) => {
//       commit('setUser', user);
//     },
//     setIsSharing: ({ commit }) => {
//       commit('setIsSharing');
//     },
//     setToken: ({ commit }, token) => {
//       commit('setToken', token);
//     },
//   },
//   modules: {},
//   getters: {
//     participants: (state, query = {}) => {
//       console.log(state.participants);
//       return state.participants
//     },
//     streams: (state) => state.streams,
//     webcast: (state) => state.webcast,
//     user: (state) => state.user,
//     isSharing: (state) => state.isSharing,
//     token: (state) => state.token,
//   },
// });
