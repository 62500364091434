import axios from 'axios';
import { NotificationProgrammatic as Notification } from 'buefy';

import router from '../router/';
import { resetState } from '../store/';
const otEventListener = function(session, commit, dispatch, rootState) {
  // yeni bir connection oluşturulduğunda bunu participant listesine dahil et
  session.on('connectionCreated', ({ connection }) => {
    console.log({ createdConnection: connection });

    commit('connectionCreated', { connection, session });
  });

  // bir connection ayrıldığında bunu participant listesinden çıkar
  session.on('connectionDestroyed', ({ connection }) => {
    console.log({ destroyedConnection: connection });

    commit('connectionDestroyed', connection);
  });

  // yeni bir stream yaratıldığında subscribe ol
  session.on('streamCreated', function({ stream }) {
    console.log({ streamCreated: stream });

    dispatch('streamCreated', stream);
  });

  // bir stream bitirildiğinde store'daki streams array'ini güncelle
  session.on('streamDestroyed', function({ stream }) {
    console.log({ streamDestroyed: stream });

    dispatch('streamDestroyed', stream);
  });

  // audio type'lı sinyalleri dinle ve audio stream'ini publish ya da unpublish et
  session.on('signal:audio', function({ data }) {
    console.log({ data });

    if (data) {
      dispatch('user/onUnMuted', null, { root: true });
    } else {
      dispatch('user/onMuted', null, { root: true });
    }
  });

  // video type'lı sinyalleri dinle ve video stream'ini publish ya da unpublish et
  session.on('signal:video', function({ data }) {
    console.log({ data });

    if (data) {
      dispatch('user/onPublishVideo', null, { root: true });
    } else {
      dispatch('user/onUnPublishVideo', null, { root: true });
    }
  });

  // ses ve video açık/kapalı değişikliklerini dinleyip participant listesini güncelleme
  session.on('streamPropertyChanged', (event) => {
    console.log({ event });

    dispatch('room/onStreamPropertyChange', event, { root: true });
  });

  // kullanıcı rolü değiştirme event'i
  // sinyalden gelen data'ya göre backend'deki kullanıcı kaydını güncelle
  // sonra gelen token'ı query'e ekleyip sayfayı reload etme
  session.on('signal:user', async function(event) {
    console.log({ event });
    let role = event.data;
    let {
      user: { current: currUser },
    } = rootState;

    if (role !== currUser.role) {
      if (
        ((role === 'moderator' || role === 'comoderator') &&
          currUser.role === 'publisher') ||
        (role === 'publisher' &&
          (currUser.role === 'moderator' || currUser.role === 'comoderator')) ||
        (role === 'moderator' && currUser.role === 'comoderator') ||
        (role === 'comoderator' && currUser.role === 'moderator')
      ) {
        let message;

        if (role === 'moderator') {
          message = 'The Admin Has Made You A Host';
        } else if (role === 'comoderator') {
          message = 'The Admin Has Made You A Co-Host';
        } else {
          message = 'The Admin Has Made You A Presenter';
        }

        Notification.open({
          duration: 5000,
          message,
          position: 'is-top-right',
          type: 'is-success',
        });
        // değiştirelecek rolü store'da güncelle
        commit('user/setCurrentUserRole', role, { root: true });

        const signalData = JSON.stringify({
          _id: currUser._id,
          role,
        });

        session.signal(
          {
            type: 'participantrolechange',
            data: signalData,
          },
          function(error) {
            if (error) {
              console.log({ error });

              // throw ErrorService.onOpenTok(error);
            } else {
              console.log('Signal successfully sent.');
            }
          },
        );
      } else {
        commit('user/setRoleChanging', true, { root: true });
        // değiştirelecek rolü store'da güncelle
        commit('user/setNewRole', role, { root: true });

        const {
          data: {
            registration: { token },
          },
        } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_API}/registration/me`,
          headers: { Authorization: `Bearer ${currUser.token}` },
        });

        //router.go({ path: `${shortLink}`, query: { t: token } });
        commit('room/endSession', token, { root: true });
        setTimeout(function() {
          resetState();
        }, 3000);
        setTimeout(function() {
          router.push({ path: ``, query: { t: token } });
        }, 5000);
      }
    }
  });

  session.on('signal:participantrolechange', function({ data }) {
    commit('room/participantRoleChange', data, { root: true });
  });

  session.on('signal:layout', function({ data }) {
    console.log({ event });

    dispatch('onLayoutChange', data);
  });

  session.on('signal:golive', function({ data }) {
    console.log({ data });

    if (data) {
      dispatch('user/isGoingLive', {}, { root: true });
    }

    commit('room/setIsLive', data, { root: true });
  });

  session.on('signal:recording', function({ data }) {
    console.log({ data });

    commit('room/setIsRecording', data, { root: true });
  });

  session.on('signal:hostLeft', (event) => {
    console.log({ event });
    console.log({ session });

    if (session.connection.connectionId !== event.from.connectionId) {
      Notification.open({
        duration: 5000,
        message: `Host isn't available. Please wait`,
        position: 'is-top-right',
        type: 'is-danger',
      });
    }
  });
};

export { otEventListener };
