import ErrorService from '../../../services/error-service';
import { db } from '../../../services/firebase';

// event'teki connection sayısı değişikliklerini realtime olarak store'da güncelle
const connectionCountListener = (eventId, commit) => {
  db.collection('event')
    .doc(eventId)
    .onSnapshot((doc) => {
      const { connectionCount = null } = doc.data();

      commit('setConnectionCount', connectionCount ? connectionCount : 0);
    });
};

const actions = {
  // backend'den event bilgilerini alma
  fetchEvent: async ({ commit, dispatch }, event) => {
    try {
      commit('setCurrent', event);
      connectionCountListener(event['_id'], commit);
      await dispatch('fetchAccount', event.accountId);

      // event'in statüsü live ise isLive'ı true olarak güncelle
      if (event.status === 'live') {
        commit('room/setIsLive', true, { root: true });
      }

      // eğer event recording halindeyse
      if (event.recording) {
        commit('room/setIsRecording', true, { root: true });
      }
    } catch (error) {
      console.log(error);

      throw ErrorService.onHttp(error, 'Event');
    }
  },
  // account bilgilerini alma ve store'a kaydetme
  fetchAccount: async ({ commit }, accountId) => {
    const accountSnap = await db
      .doc(`account/${accountId}`)
      .get()
      .catch((err) => {
        console.log(err);

        throw ErrorService.onFirebase('Error Fetching Account', err);
      });

    if (accountSnap.exists) {
      const account = accountSnap.data();

      commit('setAccount', account);
    }
  },
};

export { actions };
