<template>
  <div class="section-header">
    <div class="section-header-logo">
      <div class="header-logo">
        <img :src="current.bgImgUrl" />
      </div>

      <span>{{ current.title }}</span>
    </div>

    <div class="section-header-nav">
      <ul
        class="nav-views"
        v-if="user.role === 'moderator' || user.role === 'comoderator'"
      >
        <li>
          <button @click="changeLayout('galleryview')">
            <img src="@/img/layouts/grid.svg" />
          </button>
        </li>
        <li>
          <button @click="changeLayout('speakerview')">
            <img src="@/img/layouts/speaker-big.svg" />
          </button>
        </li>
        <li>
          <button @click="changeLayout('oneBig')">
            <img src="@/img/layouts/speaker-only.svg" />
          </button>
        </li>
        <li>
          <button
            v-show="screenId != null || isMeSharing || isMePresent"
            @click="changeLayout('screenview')"
          >
            <img src="@/img/layouts/ss-everybody.svg" />
          </button>
        </li>
        <li>
          <button
            v-show="screenId != null || isMeSharing || isMePresent"
            @click="changeLayout('screenBig')"
          >
            <img src="@/img/layouts/ss-speaker.svg" />
          </button>
        </li>
        <li>
          <button
            v-show="screenId != null || isMeSharing || isMePresent"
            @click="changeLayout('screenonly')"
          >
            <img src="@/img/layouts/ss-only.svg" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DialogProgrammatic as Dialog } from 'buefy';

import { mapGetters, mapActions, mapState } from 'vuex';
import ErrorService from '../../services/error-service';

export default {
  name: 'Header',
  computed: {
    ...mapState('user', { user: (state) => state.current }),
    ...mapState('room', {
      participants: (state) => state.connections,
      currRoom: (state) => state.current,
    }),
    ...mapGetters('room', {
      screenId: 'screenId',
      isLive: 'isLive',
    }),
    ...mapGetters('user', {
      isMeSharing: 'isMeSharing',
      isMePresent: 'isMePresent',
    }),
    ...mapGetters('event', {
      current: 'current',
    }),
  },
  methods: {
    ...mapActions({
      layoutChange: 'room/changeLayout',
      goLive: 'room/goLive',
      stopLive: 'room/stopLive',
      exit: 'room/exit',
    }),
    changeLayout(screen) {
      this.$emit('changeScreen', screen);
      this.layoutChange(screen);
    },
    exitPage() {
      const {
        participants,
        user: { role },
      } = this;
      let count = 0;

      if (role === 'publisher' || role === 'subscriber') {
        return this.exit();
      }

      participants.forEach((p) => {
        if (p.userRole === 'moderator' || p.userRole === 'comoderator') {
          count++;
        }
      });

      if (count <= 1) {
        return Dialog.confirm({
          title: 'Exit',
          message: `If you exit, there won't be any host left. Do you still want to exit?`,
          cancelText: 'No',
          confirmText: 'Yes',
          type: 'is-success',
          onConfirm: () => {
            this.currRoom.signal(
              {
                type: 'hostLeft',
              },
              (error) => {
                if (error) {
                  console.log({ error });

                  throw ErrorService.onOpenTok(error);
                } else {
                  console.log('Signal successfully sent.');

                  this.exit();
                }
              },
            );
          },
        });
      } else {
        return this.exit();
      }
    },
  },
};
</script>

<style></style>
