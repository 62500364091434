<template>
  <div
    style="position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);"
  >
    <div
      style="width: 100%; text-align: center; margin-bottom: 2rem;"
    >
      <span style="width: 100%">
        You Left the Room
      </span>
    </div>

    <div style="width: 100%; text-align: center">
      <button
        style="width: 100%;
          background: #db354f;
          border-radius: 3px;
          color: #fff;
          padding: 10px 24px;
          font-weight: 500;
          font-size: 14px;
          line-height: 11px;"
        @click="goBack()"
      >
        Go Back
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      goBack: 'room/goBack',
    }),
  },
};
</script>

<style></style>
