import { NotificationProgrammatic as Notification } from 'buefy';

const publisherEventListener = (publisher, dispatch) => {
  let activity = null;

  // publisher'ın konuşup konuşmadığını tespit etmek için event listener
  publisher.on('audioLevelUpdated', function(event) {
    let now = Date.now();

    if (event.audioLevel > 0.2) {
      if (!activity) {
        activity = { timestamp: now, talking: false };
      } else if (activity.talking) {
        activity.timestamp = now;
      } else if (now - activity.timestamp > 1000) {
        // detected audio activity for more than 1s
        // for the first time.
        activity.talking = true;
        dispatch(
          'room/setIsSpeaking',
          {
            connectionId: publisher.stream.connection.connectionId,
            isSpeaking: true,
          },
          { root: true },
        );

        publisher.element.classList.add('speaker');
      }
    } else if (activity && now - activity.timestamp > 3000) {
      // detected low audio activity for more than 3s
      if (activity.talking) {
        publisher.element.classList.remove('speaker');
        dispatch(
          'room/setIsSpeaking',
          {
            connectionId: publisher.stream.connection.connectionId,
            isSpeaking: false,
          },
          { root: true },
        );
      }

      activity = null;
    }
  });
};

const publisherConnectionListener = (publisher) => {
  let prevStats;

  window.setInterval(() => {
    publisher.getStats((error, stats) => {
      if (error) {
        console.log(error.message);

        return;
      }

      console.log(stats[0].stats);

      if (prevStats) {
        let videoPacketLossRatio =
          stats[0].stats.video.packetsLost /
          (stats[0].stats.video.packetsLost +
            stats[0].stats.video.packetsSent);
        videoPacketLossRatio = Number.isNaN(videoPacketLossRatio)
          ? 0
          : videoPacketLossRatio;

        console.log(
          'video packet loss ratio: ',
          videoPacketLossRatio,
        );

        let videoBitRate =
          (8 *
            (stats[0].stats.video.bytesSent -
              prevStats.video.bytesSent)) /
          1000 /
          60;
        videoBitRate =
          Number.isNaN(videoBitRate) ||
          stats[0].stats.video.frameRate === 0 ||
          typeof stats[0].stats.video.frameRate === 'undefined'
            ? 150
            : videoBitRate;

        console.log('video bit rate: ', videoBitRate, 'kbps');

        let audioPacketLossRatio =
          stats[0].stats.audio.packetsLost /
          (stats[0].stats.audio.packetsLost +
            stats[0].stats.audio.packetsSent);
        audioPacketLossRatio = Number.isNaN(audioPacketLossRatio)
          ? 0
          : audioPacketLossRatio;

        console.log(
          'audio packet loss ratio: ',
          audioPacketLossRatio,
        );

        let audioBitRate =
          (8 *
            (stats[0].stats.audio.bytesSent -
              prevStats.audio.bytesSent)) /
          1000 /
          60;
        audioBitRate = Number.isNaN(audioBitRate) ? 25 : audioBitRate;

        console.log('audio bit rate: ', audioBitRate, 'kbps');

        if (
          videoPacketLossRatio > 0.03 ||
          audioPacketLossRatio > 0.05 ||
          videoBitRate < 150
          // || audioBitRate < 10
        ) {
          if (document.querySelectorAll('.dialog').length === 0) {
            Notification.open({
              duration: 5000,
              message: 'Your Internet Connection Is Slow',
              position: 'is-top-right',
              type: 'is-danger',
            });
          }
        }
      }

      prevStats = stats[0].stats;
    });
  }, 1000 * 60);
};

export { publisherEventListener, publisherConnectionListener };
