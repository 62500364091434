const mutations = {
  setCurrent: (state, payload) => {
    state.current = payload;
  },
  setAccount: (state, payload) => {
    state.account = payload;
  },
  setConnectionCount: (state, payload) => {
    state.connectionCount = payload;
  },
};

export { mutations };
