<template>
  <div
    class=""
    :class="{
      'embed-responsive embed-responsive-16by9': !isScreen,
      screen: isScreen,
    }"
  >
    <div
      class="center-name"
      v-if="userName && currentStream && !currentStream.hasVideo"
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 242 47"
        preserveAspectRatio="xMinYMid meet"
        style=""
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <text x="0" y="36" font-size="36" fill="white">
          {{ userName }}
        </text>
      </svg>
      <svg
        v-if="connectionWeak"
        width="100%"
        height="100%"
        viewBox="0 0 242 47"
        preserveAspectRatio="xMinYMid meet"
        style=""
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <text x="0" y="36" font-size="36" fill="white">
          Weak Connection
        </text>
      </svg>
    </div>

    <div class="vid embed-responsive-item">
      <div
        class="video-container"
        :style="'max-width:' + maxWidth + '; max-height:' + maxHeight + ';'"
        :ref="id + 'v'"
      >
        <div
          v-if="userName && currentStream && currentStream.hasVideo"
          class="videoName"
        >
          {{ userName }}
        </div>
        <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { publisherConnectionListener } from '../../utils/publisher-event-listener';
import ErrorService from '../../services/error-service';

export default {
  name: 'OTVideo',
  data() {
    return {
      userName: null,
      isLoading: true,
      subscriber: null,
      isScreen: false,
      currentStream: null,
      id: 'me',
      connectionWeak: false,
      maxWidth: 'auto',
      maxHeight: 'auto',
    };
  },
  props: ['stream', 'publisher', 'isBig'],
  computed: {
    ...mapState('room', {
      isWatched: (state) => state.isWatched,
      currentRoom: (state) => state.current,
      streams: (state) => state.streams,
    }),
    ...mapGetters('user', {
      currentUser: 'current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrentSpeaker: 'room/setCurrentSpeaker',
      onStreamPropertyChange: 'room/onStreamPropertyChange',
      setScreenId: 'room/setScreenId',
      setIsSpeaking: 'room/setIsSpeaking',
      setPublisherIsPublishing: 'user/setPublisherIsPublishing',
    }),
    aspectRatioCalc() {
      const height = this.$refs[this.id + 'v'].parentElement.offsetHeight;
      const width = this.$refs[this.id + 'v'].parentElement.offsetWidth;
      console.log('---', height, width);
      this.maxWidth = height * 1.7778 + 'px';
      this.maxHeight = width * 0.5625 + 'px';
    },
    subscriberEventListener() {
      let activity = null;
      this.subscriber.on('audioLevelUpdated', (event) => {
        let now = Date.now();
        if (event.audioLevel > 0.05) {
          if (!activity) {
            activity = { timestamp: now, talking: false };
          } else if (activity.talking) {
            activity.timestamp = now;
          } else if (now - activity.timestamp > 500) {
            // detected audio activity for more than 1s
            // for the first time.
            activity.talking = true;
            console.log('--2', this.subscriber);
            if (this.subscriber) {
              this.setCurrentSpeaker({
                id: this.subscriber.streamId,
              });
              this.setIsSpeaking({
                connectionId: this.subscriber.stream.connection.connectionId,
                isSpeaking: true,
              });
            }

            //this.parent.$refs["big"].appendChild(this.refs[this.id]);
            //this.$refs["video"].classList.add("speaker");
          }
        } else if (activity && now - activity.timestamp > 1000) {
          // detected low audio activity for more than 3s
          if (activity.talking) {
            //this.$refs["video"].classList.remove("speaker");
            if (this.subscriber) {
              console.log('subscribernotTaklikn');
              this.setIsSpeaking({
                connectionId: this.subscriber.stream.connection.connectionId,
                isSpeaking: false,
              });
            }
          }

          activity = null;
        }
      });

      this.subscriber.on('videoDisabled', (event) => {
        console.log({ VideoDisabledEvent: event });
        Object.assign(this, {
          connectionWeak: event.reason === 'quality',
        });
        console.log(this.connectionWeak);
      });

      this.subscriber.on('videoEnabled', (event) => {
        console.log({ VideoEnabledEvent: event });
        Object.assign(this, { connectionWeak: false });
        console.log(this.connectionWeak);
      });
    },
    publisherEventListener() {
      let activity = null;
      this.publisher.on('audioLevelUpdated', (event) => {
        let now = Date.now();
        if (event.audioLevel > 0.05) {
          if (!activity) {
            activity = { timestamp: now, talking: false };
          } else if (activity.talking) {
            activity.timestamp = now;
          } else if (now - activity.timestamp > 500) {
            // detected audio activity for more than 1s
            // for the first time.
            activity.talking = true;
            if (this.publisher) {
              this.setCurrentSpeaker({ id: this.publisher.streamId });
              this.setIsSpeaking({
                connectionId: this.publisher.stream.connection.connectionId,
                isSpeaking: true,
              });
            }

            //this.parent.$refs["big"].appendChild(this.refs[this.id]);
            //this.$refs["video"].classList.add("speaker");
          }
        } else if (activity && now - activity.timestamp > 3000) {
          // detected low audio activity for more than 3s
          if (activity.talking) {
            //this.$refs["video"].classList.remove("speaker");
            if (this.publisher) {
              console.log('menotTaklikn');
              this.setIsSpeaking({
                connectionId: this.publisher.stream.connection.connectionId,
                isSpeaking: false,
              });
            }
          }

          activity = null;
        }
      });
    },
  },
  async mounted() {
    if (this.publisher) {
      console.log("Hello it's me", this.publisher);
      this.$refs['mev'].appendChild(this.publisher.videoElement());
      //commit("addVideo", { id: subscriber.streamId, videoEl: event.element });
      this.userName = this.currentUser.name;

      this.currentRoom.publish(this.publisher, (error) => {
        if (error) {
          console.log(error);

          throw ErrorService.onOpenTok(error);
        }

        console.log('published');
        this.currentStream = this.publisher.stream;
        this.setPublisherIsPublishing(true);
        this.isLoading = false;
        this.onStreamPropertyChange(
          {
            changedProperty: 'hasAudio',
            newValue: this.publisher.stream.hasAudio,
            stream: this.publisher.stream,
          },
          { root: true },
        );
        this.onStreamPropertyChange(
          {
            changedProperty: 'hasVideo',
            newValue: this.publisher.stream.hasVideo,
            stream: this.publisher.stream,
          },
          { root: true },
        );

        publisherConnectionListener(this.publisher);
        this.publisherEventListener();
      });
    } else if (this.stream) {
      // if check for streams also i need this sometimes for testing.
      console.log(this.stream);
      this.currentStream = this.stream;
      if (this.stream) {
        this.subscriber = this.currentRoom.subscribe(
          this.stream,
          {
            insertDefaultUI: false,
          },

          (error) => {
            if (error) {
              console.log(error);

              throw ErrorService.onOpenTok(error);
            }
          },
        );
        this.userName = this.stream.name;

        if (
          this.stream.videoType == 'screen' ||
          this.stream.videoType == 'custom'
        ) {
          console.log('screen', this.stream.id);
          this.isScreen = true;
          this.setScreenId(this.stream.id);
        }

        this.id = this.stream.id;
        console.log(this.id);

        this.subscriber.on('videoElementCreated', (event) => {
          this.$refs[this.id + 'v'].appendChild(event.element);
          console.log(event);
          console.log(this.subscriber);
          console.log(this.subscriber.resolution);
          this.subscriber.setPreferredResolution({
            width: 1280,
            height: 720,
          });

          this.isLoading = false;
          this.subscriberEventListener();
        });
      }
    }
  },
  destroyed() {
    if (this.isScreen) {
      this.setScreenId(null);
    }

    if (this.publisher) {
      console.log(this.publisher);
    }

    if (this.subscriber) {
      console.log(this.subscriber.disconnect());
    }
  },
};
</script>

<style lang="scss">
.loading-icon {
  opacity: 0.5;
}
.video-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.vid {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 0 0 0 rgba(0, 0, 0, 0.12);
  }
}
.screen {
  .videoName {
    bottom: 12px;
  }
}
.videoName {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: left;
  padding: 6px 12px;
  left: 6px;
  bottom: 6px;
  border-radius: 32px;
  z-index: 10;
  font-size: 14px;
}

.center-name {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  height: fit-content;
  padding: 0;
  width: 30%;
  z-index: 1;
}

.speaker {
  //outline: 2px solid #28a745;
  box-shadow: 0 0 0 4px #28a745;
}
</style>
