<template>
  <div class="section-miniwrapper">
    <div class="section-attendies">
      <b-tabs v-model="activeTab" type="is-toggle-rounded" size="is-small" class="flex-center">
        <b-tab-item label="Presenters"></b-tab-item>
        <b-tab-item label="Attendees"></b-tab-item>
      </b-tabs>
    </div>

    <div class="section-people">
      <ul>
        <li
          v-for="participant in participantFilter()"
          :key="participant.connection.connectionId"
        >
          <PeopleListItem v-bind:participant="participant" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PeopleListItem from './PeopleListItem';

export default {
  name: 'People',
  components: {
    PeopleListItem,
  },
  computed: {
    ...mapState('user', { user: (state) => state.current }),
    ...mapState('room', { participants: (state) => state.connections }),
  },
  data(){
    return {
      activeTab: 0
    }
  },
  methods: {
    participantFilter(){
      if (this.activeTab == 1) {
        return this.participants.filter(v => v.role == "subscriber");
      } else {
        return this.participants.filter(v => v.role != "subscriber");
      }
    }
  }
};
</script>

<style></style>
