<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Join to webinar</p>
    </header>
    <section class="modal-card-body">
      <b-button type="is-success is-light" @click="connectNow()"
        >Connect with computer audio</b-button>
    </section>
  </div>
</template>

<script>
import OT from "@opentok/client";

export default {
  mounted(){
    const audioAllow = sessionStorage.getItem("AllowAudio");
    if (audioAllow) {
      this.unblockAudio();
    }
  },
  methods: {
    connectNow() {
      this.$store.commit('user/setAttendeeStatus', true);
      this.unblockAudio();
      this.$emit("close");
    },
    unblockAudio() {
      OT.unblockAudio();
      sessionStorage.setItem("AllowAudio", true);
    }
  }
};
</script>

<style>
</style>
