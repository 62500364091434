<template>
  <div
    ref="mediaLayout"
    class="media-layout"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div :class="[layoutClass]" id="scroll-element" class="scroll hidden">
      <div class="scroll-child"></div>
    </div>
    <div
        :class="[
          layoutClass,
          gridClass,
          containerClass,
          'sc-' + screenCount(),
          'scb-' + screenCountInBig(),
        ]"
        class="grid-media-layout">
      <div
          v-if="isMeSharing"
          class="screen"
          style="justify-content: center;align-items: center;"
      >
          <span class="screen-share-text"
          >You are sharing your screen</span
          >

        <button class="stop-sharing" @click="destroyScreenShare">
          Stop Sharing Your Screen
        </button>
      </div>
      <OTVideo
          v-if="publisherIsReady && publisher"
          :publisher="publisher"
          :style="videoStyle"
          ref="me"
      />
      <OTVideo
          :stream="stream"
          :style="videoStyle"
          v-for="stream in streams"
          :key="stream.id"
          :ref="stream.id"
          v-show="stream.videoType != 'screen'"
      />
    </div>
    <ParticipantControls v-if="publisherIsReady" v-show="hover" />
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */

import _ from 'lodash';
import { ResizeObserver } from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
import { mapState, mapGetters, mapActions } from 'vuex';
import OTVideo from '@/components/media/OTVideo2.vue';
import ParticipantControls from '@/components/media/ParticipantControls.vue';

export default {
  name: 'MediaLayout',
  components: {
    ResizeObserver,
    OTVideo,
    ParticipantControls,
  },
  props: {
    sidebar: false,
  },
  data() {
    return {
      isSharing: false,
      isSettigsModalActive: false,
      hasBig: false,
      numVideos: 0,
      numRows: 0,
      bigStyle: {},
      videoWidth: 640,
      videoHeight: 360,
      videoStyle: {},
      publisher: null,
      screenSharePublisher: null,
      layoutClass: 'grid',
      bigId: null,
      hover: false,
      gridClass: '',
      containerClass: '',
      elem_height: 0
    };
  },
  computed: {
    ...mapState('room', {
      streams: (state) => state.streams,
      isWatched: (state) => state.isWatched,
      currentRoom: (state) => state.current,
    }),
    ...mapGetters('room', {
      videoList: 'videoList',
      currentSpeaker: 'currentSpeaker',
      screenId: 'screenId',
      layout: 'layout',
      isLive: 'isLive',
    }),
    ...mapGetters('user', {
      currentUser: 'current',
      userPublisher: 'publisher',
      publisherIsReady: 'publisherIsReady',
      isMeSharing: 'isMeSharing',
    }),
  },
  watch: {
    layout() {
      this.changeLayout();
    },
    isLive(val) {
      const self = this;
      if (val &&
        this.currentUser.role !== 'moderator' &&
        this.currentUser.role !== 'publisher' &&
        this.currentUser.role !== 'comoderator') {
        setTimeout(() => {
          self.changeLayout();
        }, 1);
      }
    },
    sidebar() {
      this.updateSize();
      this.changeLayout();

    },
    publisherIsReady(n, o) {
      console.log('---------', n);
      if (n != o && n === true) {
        this.initPublisher();
      }
    },
    // store'daki streams objesini izle
    streams(v) {
      const self = this;
      console.log('new-stream', v);
      setTimeout(function() {
        self.updateSize();
        self.aspectRatioCalc();
      }, 1);
    },
    currentSpeaker(newVal, oldVal) {
      let speaker = null;
      let oldSpeaker = null;

      try {
        if (newVal && newVal != oldVal) {
          if (Array.isArray(this.$refs[newVal])) {
            speaker = this.$refs[newVal][0].$el;
          } else if (this.$refs[newVal]) {
            speaker = this.$refs[newVal].$el;
          }
          if (oldVal) {
            if (Array.isArray(this.$refs[oldVal])) {
              oldSpeaker = this.$refs[oldVal][0].$el;
            } else if (this.$refs[oldVal]) {
              oldSpeaker = this.$refs[oldVal].$el;
            }
          }

          document
            .querySelectorAll('.embed-responsive')
            .forEach((v) => {
              v.classList.remove('speaking-now');
            });

          if (this.publisher && this.publisher.streamId != newVal) {
            if (
              speaker != null &&
              !speaker.classList.contains('speaking-now')
            ) {
              speaker.classList.add('speaking-now');
            }

            if (
              oldSpeaker != null &&
              !oldSpeaker.classList.contains('speaking-now')
            ) {
              oldSpeaker.classList.remove('speaking-now');
            }
          } else if (oldVal) {
            if (
              oldSpeaker != null &&
              !oldSpeaker.classList.contains('speaking-now')
            ) {
              oldSpeaker.classList.add('speaking-now');
            }
          }
        }
      } catch (e) {
        console.warn(e);
      }
    },
    isMeSharing() {
      this.changeLayout();
    },
    screenId(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.$nextTick().then(() => {});
      } else if (
        newVal === null &&
        (this.layout === 'screenview' ||
          this.layout === 'screenBig' ||
          this.layout === 'screenonly')
      ) {
        this.layoutChange('galleryview');
      }
      this.changeLayout();
    },
  },
  methods: {
    ...mapActions({
      subscribeStreams: 'room/subscribeStreams',
      isWatching: 'room/isWatching',
      addVideo: 'room/addVideo',
      streamCreated: 'room/streamCreated',
      setScreenId: 'room/setScreenId',
      publish: 'user/publish',
      initScreenShare: 'user/initScreenShare',
      destroyScreenShare: 'user/destroyScreenShare',
      changeAudioDevice: 'user/changeAudioDevice',
      changeVideoDevice: 'user/changeVideoDevice',
      destroyPublisher: 'user/destroyPublisher',
      layoutChange: 'room/changeLayout',
    }),
    initPublisher() {
      this.publisher = this.userPublisher;
      const self = this;
      if (this.$route.query.test) {
        const testCount = this.$route.query.test;
        for (let i = 0; i < testCount; i++) {
          setTimeout(function() {
            const testStream = self.userPublisher.stream;
            //testStream.id = 'test-'+i+testStream.id;
            if (testStream) {
              testStream['videoType'] = 'test';
              self.streams.push(testStream);
            }
          }, i * 2000);
        }
      }
      setTimeout(function() {
        self.updateSize();
      }, 1);
    },
    screenCount() {
      if (
        document.querySelector('.grid-media-layout') &&
        document.querySelectorAll('.grid-media-layout > div').length >
          0
      ) {
        return document.querySelectorAll('.grid-media-layout > div')
          .length;
      } else {
        return 0;
      }
    },
    screenCountInBig() {
      if (
        document.querySelector('.grid-media-layout') &&
        document.querySelector('.grid-media-layout').childNodes
          .length > 0
      ) {
        return (
          document
            .querySelector('.grid-media-layout')
            .querySelectorAll('.speaking-now').length +
          document
            .querySelector('.grid-media-layout')
            .querySelectorAll('.screen').length
        );
      } else {
        return 0;
      }
    },
    updateSize() {
      let width = this.$refs['mediaLayout'].clientWidth;
      let height = this.$refs['mediaLayout'].clientHeight;
      this.numVideos = this.screenCount();
      width = width - 32;
      if (this.numVideos == 1) {
        if (width / 1.78 > height) {
          this.videoWidth = height * 1.78;
          this.videoHeight = height;
        } else {
          this.videoWidth = width;
          this.videoHeight = width / 1.78;
        }
      } else {
        let numRowsArray = [];
        for (let i of _.range(2, 11)) {
          if (i === 0) {
            numRowsArray[i] = 0;
          } else {
            numRowsArray[i] = Math.round(
              (this.numVideos / i) * (width / i / 1.77),
            );
          }
        }
        let nearest = _.sortedIndexBy(
          numRowsArray,
          height,
          (num) => -num,
        );

        if (nearest <= 2) {
          this.numRows = 2;
        } else {
          this.numRows = nearest;
        }
        if (this.hasBig) {
          this.videoHeight = height * 0.25 - 32;
          this.videoWidth = this.videoHeight * 1.777;
        } else {
          this.videoWidth = width / this.numRows - 4;
          this.videoHeight = this.videoWidth / 1.777 - 4;
        }
      }

      this.videoStyle = {
        width: this.videoWidth + 'px',
        height: this.videoHeight + 'px',
      };
    },
    changeLayout() {
      const self = this;
      this.containerClass = '';
      if (this.layout == 'galleryview') {
        this.clearScroll();
        this.layoutClass = 'grid';
        this.gridClass = '';
      } else if (this.layout == 'speakerview') {
        this.layoutClass = 'split';
        this.gridClass = 'presenteronly';
        setTimeout(function () {
          self.scrollCalcInit();
        },500);

      } else if (this.layout == 'oneBig') {
        this.layoutClass = 'oneBig';
        this.gridClass = 'presenteronly';
        this.clearScroll();
      } else if (this.layout == 'screenview') {
        this.layoutClass = 'split';
        this.scrollCalcInit();
        this.gridClass = 'screenonly';
        this.containerClass = 'swp-true';
      } else if (this.layout == 'screenBig') {
        this.layoutClass = 'split';
        this.gridClass = 'screenonly';
        this.containerClass = 'swp-false';
      } else if (this.layout == 'screenonly') {
        this.layoutClass = 'oneBig';
        this.gridClass = 'screenonly';
        this.clearScroll();
      }

      if (
        this.layoutClass == 'grid' ||
        this.gridClass == 'screenonly'
      ) {
        this.updateSize();
      } else {
        this.videoStyle = {};
      }
      this.aspectRatioCalc();


    },
    clearScroll() {
      for (var i = 0; i < 30; i++) {
        document.querySelector('.media-layout').classList.remove('scroll-to-' + i);
      }

      document.querySelector('.media-layout').classList.add('scroll-to-0');
    },
    aspectRatioCalc(){
      const self = this;
      setTimeout(() => {
        if (self.$refs.me) {
          self.$refs.me.aspectRatioCalc();
        }
      }, 100);
    },
    scrollCalcInit() {
      if (document.querySelector('#scroll-element')) {
        document.querySelector('#scroll-element').removeEventListener('scroll', this.scrollListener);

        const w_cont = document.querySelector('.grid-media-layout')
          .clientWidth;

        const h_cont = document.querySelector('#scroll-element')
          .clientHeight;
        const elem_height = (w_cont / 5) * 0.5622;

        const screen_count =
          this.screenCount() - this.screenCountInBig();

        if (screen_count <= Math.floor(h_cont / elem_height)) {
          document
            .querySelector('#scroll-element')
            .classList.add('hidden');
        } else {
          document
            .querySelector('#scroll-element')
            .classList.remove('hidden');
        }

        setTimeout(() => {
          document.querySelector('.scroll-child').style.height =
            screen_count * (elem_height + 8) + 'px';
        }, 500);


        this.elem_height = elem_height;
        document
          .querySelector('#scroll-element')
          .addEventListener('scroll', this.scrollListener);
      }
    },
    scrollListener(e) {
      let gap = 0;
      const elem_height = this.elem_height;
      for (var i = 0; i < this.screenCount(); i++) {
        document
          .querySelector('.media-layout')
          .classList.remove('scroll-to-' + i);
        if (e.srcElement.scrollTop > i * elem_height) {
          gap = i + 1;
        }
      }

      document.querySelector('.media-layout').classList.add('scroll-to-' + gap);
    }
  },
  async mounted() {
    await this.isWatching({
      isWatched: true,
    });

    if (this.publisherIsReady) {
      this.initPublisher();
    }

    const self = this;
    setTimeout(function () {
      self.changeLayout();
      self.scrollCalcInit();
    },1000);
  },
  created() {},
  beforeDestroy: function() {
    this.destroyPublisher();
    this.isWatching({ isWatched: false, subscribeProps: {} });
  },
};
</script>

<style lang="scss">
.scroll-to-1 {
  .embed-responsive:not(.speaking-now) {
    transform: translateY(calc((11.25vw - 48.375px) * -1));
  }
}
.scroll-to-2 {
  .embed-responsive:not(.speaking-now) {
    transform: translateY(calc((11.25vw - 48.375px) * -2));
  }
}
.scroll-to-3 {
  .embed-responsive:not(.speaking-now) {
    transform: translateY(calc((11.25vw - 48.375px) * -3));
  }
}
.scroll-to-4 {
  .embed-responsive:not(.speaking-now) {
    transform: translateY(calc((11.25vw - 48.375px) * -4));
  }
}
.scroll-to-5 {
  .embed-responsive:not(.speaking-now) {
    transform: translateY(calc((11.25vw - 48.375px) * -5));
  }
}
.scroll-to-6 {
  .embed-responsive:not(.speaking-now) {
    transform: translateY(calc((11.25vw - 48.375px) * -6));
  }
}
.scroll-to-7 {
  .embed-responsive:not(.speaking-now) {
    transform: translateY(calc((11.25vw - 48.375px) * -7));
  }
}
.scroll {
  overflow-y: auto;
  z-index: 1000;
  height: calc(100% - 128px);
}

.scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 1.5px;
}

.scroll::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #f3f3f3;
  cursor: pointer;
  border-radius: 1.5px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.scroll .scroll-child {
  height: 3000px;
  z-index: 10;
}

.media-layout {
  width: 100%;
  display: flex;
  align-content: center;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  height: calc(100vh - 132px);
}

.grid.grid-media-layout {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  height: fit-content;
}

.meeting-wrapper:not(.mobile) {
  .scroll {
    width: calc(20vw - 86px);
    right: 416px;
    position: fixed;
  }

  .split.grid-media-layout.sc-1 {
    grid-template-rows: calc(11.25vw - 48.375px) auto;
    .speaking-now {
      // grid-row: 1 / 3;
    }
  }

  .split.grid-media-layout.sc-2 {
    grid-template-rows:
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px)
      auto;
    .speaking-now {
      // grid-row: 1 / 4;
    }
  }

  .split.grid-media-layout.sc-3 {
    grid-template-rows:
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) calc(
        11.25vw - 48.375px
      )
      auto;
    .speaking-now {
      // grid-row: 1 / 5;
    }
  }

  .split.grid-media-layout.sc-4 {
    grid-template-rows:
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) calc(
        11.25vw - 48.375px
      )
      calc(11.25vw - 48.375px) auto;
    .speaking-now {
      // grid-row: 1 / 6;
    }
  }

  .split.grid-media-layout.sc-5 {
    grid-template-rows:
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) calc(
        11.25vw - 48.375px
      )
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) auto;
    .speaking-now {
      // grid-row: 1 / 7;
    }
  }

  .split.grid-media-layout.sc-6 {
    grid-template-rows:
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) calc(
        11.25vw - 48.375px
      )
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) calc(
        11.25vw - 48.375px
      )
      auto;
    .speaking-now {
      // grid-row: 1 / 8;
    }
  }

  .split.grid-media-layout.sc-7 {
    grid-template-rows:
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) calc(
        11.25vw - 48.375px
      )
      calc(11.25vw - 48.375px) calc(11.25vw - 48.375px) calc(
        11.25vw - 48.375px
      )
      calc(11.25vw - 48.375px)
      auto;
    .speaking-now {
      // grid-row: 1 / 9;
    }
  }
}

.meeting-wrapper.mobile {
  .scroll {
    width: 20vw;
    right: 0px;
    position: fixed;
  }

  .split.grid-media-layout.sc-1 {
    grid-template-rows: 11.25vw auto;
    .speaking-now {
      // grid-row: 1 / 3;
    }
  }

  .split.grid-media-layout.sc-2 {
    grid-template-rows: 11.25vw 11.25vw auto;
    .speaking-now {
      // grid-row: 1 / 4;
    }
  }

  .split.grid-media-layout.sc-3 {
    grid-template-rows: 11.25vw 11.25vw 11.25vw auto;
    .speaking-now {
      // grid-row: 1 / 5;
    }
  }

  .split.grid-media-layout.sc-4 {
    grid-template-rows: 11.25vw 11.25vw 11.25vw 11.25vw auto;
    .speaking-now {
      // grid-row: 1 / 6;
    }
  }

  .split.grid-media-layout.sc-5 {
    grid-template-rows: 11.25vw 11.25vw 11.25vw 11.25vw 11.25vw auto;
    .speaking-now {
      // grid-row: 1 / 7;
    }
  }

  .split.grid-media-layout.sc-6 {
    grid-template-rows: 11.25vw 11.25vw 11.25vw 11.25vw 11.25vw 11.25vw auto;
    .speaking-now {
      // grid-row: 1 / 8;
    }
  }

  .split.grid-media-layout.sc-7 {
    grid-template-rows: 11.25vw 11.25vw 11.25vw 11.25vw 11.25vw 11.25vw 11.25vw auto;
    .speaking-now {
      // grid-row: 1 / 9;
    }
  }
}

.split.grid-media-layout {
  width: calc(100% - 15px);
  height: calc(100vh - 122px);
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(5, 20%);

  &:not(.screenonly) {
    .speaking-now {
      grid-column: 1 / 5;
      grid-row: 1 / 7;
      grid-row-start: 0;
      max-height: calc(100vh - 132px);
      width: 100%!important;
      height: 100%!important;

      .embed-responsive-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      video {
        height: 100% !important;
      }
    }
  }

  .screen {
    display: none;
  }

  .embed-responsive:not(.speaking-now) {
    grid-column: 5 / 5;
    width: auto !important;
    height: 100% !important;
    .videoName {
      padding: 4px 8px!important;
      font-size: 10px!important;
    }
  }
}

.oneBig.grid-media-layout {
  width: calc(100% - 15px);
  height: calc(100vh - 122px);
  display: grid;
  grid-template-columns: repeat(5, 20%);

  .speaking-now {
    grid-column: 1 / 6;
    grid-row: 1 / 8;
    grid-row-start: 0;
    max-height: calc(100vh - 122px);
    width: 100%!important;
    height: calc(100% - 20px)!important;
    .embed-responsive-item {
      display: flex;
      align-items: center;
    }

    video {
      height: 100% !important;
    }
  }

  .screen {
    display: none;
  }

  .embed-responsive:not(.speaking-now) {
    display: none;
  }
}

.oneBig.grid-media-layout.screenonly {
  width: calc(100% - 15px);
  height: calc(100vh - 122px);
  display: grid;
  grid-template-columns: repeat(5, 20%);

  .screen {
    grid-column: 1 / 6;
    grid-row: 1 / 8;
    grid-row-start: 0;
    flex-flow: column;
    max-height: calc(100vh - 122px);
    width: auto !important;
    height: auto !important;
    display: flex!important;
    justify-content: center;
    .embed-responsive-item {
      display: flex;
      align-items: center;
    }

    video {
      height: auto !important;
    }
  }

  .speaking-now {
    display: none;
  }

  .embed-responsive:not(.screen) {
    display: none;
  }
}

.split.grid-media-layout.screenonly.swp-false,
.split.grid-media-layout.screenonly.swp-true {
  .screen {
    display: flex !important;
    width: auto !important;
    height: auto !important;
    flex-flow: column;
    grid-column: 1 / 5;
    grid-row: 1 / 7;
    grid-row-start: 0;
    max-height: calc(100vh - 122px);
    justify-content: center;
    .embed-responsive-item {
      display: flex;
      align-items: center;
    }

    video {
      height: auto !important;
    }
  }

  .embed-responsive {
    grid-column: 5 / 5;
    width: auto !important;
    height: auto !important;
  }
}

.grid-media-layout:not(.screen-only) {
  .screen {
    display: none;
  }
}

.split.grid-media-layout.screenonly.swp-false {
  .embed-responsive:not(.speaking-now) {
    display: none;
  }
}

#scroll-element {
  display: none;
  overflow-y: hidden;
}

#scroll-element.split {

  display: block;
  &:not(.hidden) {
    overflow-y: scroll;
  }
}

.screen-share-text {
  width: 100%;
}

.stop-sharing {
  background: #35db9f;
  border-radius: 3px;
  color: #fff;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 11px;
}

.speaking-now {
  .video-container {

  }
}
</style>
