var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-screen-control flex-center"},[_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"control-button",on:{"click":function($event){return _vm.$store.commit('user/setSettingsModal', true)}}},[_c('i',{staticClass:"bi bi-gear icon"}),_c('span',{staticClass:"control-button-text"},[_vm._v("Settings")])]),(_vm.publisherIsPublishing)?_c('button',{staticClass:"control-button",staticStyle:{"min-width":"60px"},on:{"click":function($event){return _vm.changeAudio()}}},[(_vm.publisher.stream && _vm.publisher.stream.hasAudio)?_c('i',{staticClass:"bi bi-mic icon"}):_vm._e(),(_vm.publisher.stream && !_vm.publisher.stream.hasAudio)?_c('i',{staticClass:"bi bi-mic-mute-fill icon red"}):_vm._e(),_c('span',{staticClass:"control-button-text"},[_vm._v("Mic "),(_vm.publisher.stream && _vm.publisher.stream.hasAudio)?_c('span',[_vm._v("On")]):_vm._e(),(_vm.publisher.stream && !_vm.publisher.stream.hasAudio)?_c('span',[_vm._v("Off")]):_vm._e()])]):_vm._e(),(_vm.publisherIsPublishing)?_c('button',{staticClass:"control-button",staticStyle:{"min-width":"70px"},on:{"click":function($event){return _vm.changeVideo()}}},[(_vm.publisher.stream.hasVideo)?_c('i',{staticClass:"bi bi-camera-video-fill icon"}):_vm._e(),(!_vm.publisher.stream.hasVideo)?_c('i',{staticClass:"bi bi-camera-video-off-fill icon red"}):_vm._e(),_c('span',{staticClass:"control-button-text"},[_vm._v(" Video "),(_vm.publisher.stream.hasVideo)?_c('span',[_vm._v("On")]):_vm._e(),(!_vm.publisher.stream.hasVideo)?_c('span',[_vm._v("Off")]):_vm._e()])]):_vm._e(),_c('button',{staticClass:"control-button",on:{"click":function($event){return _vm.shareScreen()}}},[_c('i',{class:{
          'bi bi-tv-fill icon red': _vm.isMeSharing,
          'bi bi-tv-fill icon green': !_vm.isMeSharing,
        }}),_c('span',{staticClass:"control-button-text",style:({ color: _vm.isMeSharing ? 'red' : 'green' })},[_vm._v(_vm._s(_vm.isMeSharing ? 'Stop Sharing' : 'Share Screen')+" ")])]),_c('button',{staticClass:"control-button",on:{"click":function($event){return _vm.initPresent()}}},[_c('i',{class:{
          'bi bi-tv-fill icon red': _vm.isMePresent,
          'bi bi-tv-fill icon green': !_vm.isMePresent,
        }}),_c('span',{staticClass:"control-button-text",style:({ color: _vm.isMePresent ? 'red' : 'green' })},[_vm._v(_vm._s(_vm.isMePresent ? 'Stop Presenting' : 'Present'))])]),_c('button',{staticClass:"control-button",on:{"click":function($event){return _vm.$store.commit('user/setLayoutBackgroundModal', true)}}},[_c('i',{staticClass:"bi bi-gear icon"}),_c('span',{staticClass:"control-button-text"},[_vm._v("Background")])])]),_c('div',{staticClass:"button-group"},[(
        (_vm.user.role === 'moderator' || _vm.user.role === 'comoderator') && !_vm.isLive
      )?_c('button',{staticClass:"nav-live",on:{"click":function($event){return _vm.goLive()}}},[_vm._v(" Go Live ")]):_vm._e(),(
        (_vm.user.role === 'moderator' || _vm.user.role === 'comoderator') && _vm.isLive
      )?_c('button',{staticClass:"nav-stop",on:{"click":function($event){return _vm.stopLive()}}},[_vm._v(" Stop Live ")]):_vm._e(),(
        (_vm.user.role === 'moderator' || _vm.user.role === 'comoderator') &&
          _vm.isRecording
      )?_c('button',{staticClass:"nav-stop-recording",on:{"click":function($event){return _vm.stopRecording()}}},[_vm._v(" Stop Recording ")]):_vm._e(),(
        (_vm.user.role === 'moderator' || _vm.user.role === 'comoderator') &&
          !_vm.isRecording
      )?_c('button',{staticClass:"nav-start-recording",on:{"click":function($event){return _vm.startRecording()}}},[_vm._v(" Start Recording ")]):_vm._e(),_c('button',{staticClass:"nav-leave",on:{"click":function($event){return _vm.exit()}}},[_vm._v(" Leave ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }