<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Join to webinar</p>
    </header>
    <section class="modal-card-body">
      <div
        class="embed-responsive embed-responsive-16by9 publisherPreview"
      >
        <div ref="publisherVideo" class="embed-responsive-item"></div>
      </div>
      <div class="switch-group">
        <b-switch
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          v-model="audio"
          >Audio</b-switch
        >
        <b-switch
          type="is-info"
          passive-type="is-dark"
          :left-label="true"
          v-model="video"
          >Video</b-switch
        >
      </div>
      <b-button
        :disabled="!videoElementCreated"
        style="float: right"
        type="is-success"
        @click="join()"
        >Join</b-button
      >
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DialogProgrammatic as Dialog } from 'buefy';

export default {
  data() {
    return {
      audio: true,
      video: true,
      videoElementCreated: false,
    };
  },
  computed: {
    ...mapGetters('user', {
      currentUser: 'current',
      publisher: 'publisher',
    }),
    ...mapGetters('event', {
      account: 'account',
    }),
    ...mapGetters('room', {
      streams: 'streams',
    }),
  },
  methods: {
    ...mapActions({
      publish: 'user/publish',
      setPublisherIsReady: 'user/setPublisherIsReady',
      changeAudioDevice: 'user/changeAudioDevice',
      changeVideoDevice: 'user/changeVideoDevice',
    }),
    join() {
      const { webinarCapacity = null } = this.account;
      const streamCount = this.streams.length;

      // publish etmeden önce account'un max presenter sayısıyla elimizdeki presenter sayısını karşılaştır
      // limiti aşıyorsa publish etme
      if (
        webinarCapacity &&
        streamCount + 1 <= webinarCapacity.presenter
      ) {
        this.setPublisherIsReady(true);
        this.$emit('close');
        sessionStorage.setItem('AllowAudio', true);
      } else {
        Dialog.alert({
          message: 'Presenter Capacity Is Exceeded',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        });
      }
    },
  },
  watch: {
    audio(n, o) {
      if (n != o) {
        this.publisher.publishAudio(n);
      }
    },
    video(n, o) {
      if (n != o) {
        this.publisher.publishVideo(n);
      }
    },
  },
  async mounted() {
    await this.publish();
    if (this.publisher) {
      this.publisher.on('videoElementCreated', async (event) => {
        this.$refs['publisherVideo'].appendChild(event.element);
        Object.assign(this, { videoElementCreated: true });
      });
    }
  },
};
</script>

<style>
.publisherPreview {
  max-width: 640px !important;
}
.switch-group {
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
