const state = {
  current: null,
  connections: [],
  streams: [],
  videoList: [],
  isWatched: false,
  subscribeProps: {},
  currentSpeaker: null,
  screenId: null,
  layout: 'galleryview',
  isLive: false,
  pageIndex: 0,
  exit: false,
  newMessages: 0,
  isRecording: false,
};

export { state };
