import Vue from 'vue';
import store from '../store/';

const sendError = async (error) => {
  const {
    state: {
      user: { current },
    },
  } = store;
  let person = {};

  if (current) {
    person = {
      id: current._id, // required
      username: current.name,
      email: current.uid,
    };
  }

  Vue.prototype.$rollbar.configure({
    payload: {
      person,
    },
  });
  Vue.prototype.$rollbar.error(error);
};

class ErrorService extends Error {
  constructor({
    message = 'Unknown Error Occurred',
    status = '500',
    type = 'General',
    error,
  }) {
    sendError(error);

    super(message);
    this.status = status;
    this.type = type;
  }

  static onGeneral(error) {
    return new ErrorService({
      message: 'Unknown Error Occurred',
      status: '500',
      type: 'General',
      error,
    });
  }

  static onHttp(error, type) {
    const response = error.response;

    switch (response.status) {
      case 401:
        return new ErrorService({
          message: 'Unauthorized',
          status: response.status,
          type,
          error,
        });
      case 404:
        return new ErrorService({
          message: `${type} Not Found`,
          status: response.status,
          type,
          error,
        });
      case 500:
        return new ErrorService({
          message: 'Unknown Error Occurred',
          status: response.status,
          type,
          error,
        });
      default:
        return new ErrorService({
          message: 'Unknown Error Occurred',
          status: response.status,
          type: 'API',
          error,
        });
    }
  }

  static onFirebase(message, error) {
    return new ErrorService({
      message,
      status: 400,
      type: 'Firebase',
      error,
    });
  }

  static onOpenTok(error) {
    const status = error.code;

    switch (status) {
      case 400:
      case 413:
        return new ErrorService({
          message: 'Invalid Signal',
          status,
          type: 'Opentok',
          error,
        });
      case 404:
        return new ErrorService({
          message: 'Connection Does Not Exist',
          status,
          type: 'Opentok',
          error,
        });
      case 1004:
      case 1520:
      case 1530:
        return new ErrorService({
          message: 'Unauthorized',
          status,
          type: 'Opentok',
          error,
        });
      case 1006:
      case 2001:
        return new ErrorService({
          message: 'Connection Failed',
          status,
          type: 'Opentok',
          error,
        });
      case 500:
      case 1010:
        return new ErrorService({
          message: 'Client Is Not Connected',
          status,
          type: 'Opentok',
          error,
        });
      case 1500:
        return new ErrorService({
          message: 'Unable to Publish',
          status,
          type: 'Opentok',
          error,
        });
      case 1535:
        return new ErrorService({
          message: 'Invalid Stream',
          status,
          type: 'Opentok',
          error,
        });
      case 1550:
      case 1551:
      case 1552:
        return new ErrorService({
          message: 'Screen Sharing Is Not Enabled',
          status,
          type: 'Opentok',
          error,
        });
      case 1005:
      case 1011:
        return new ErrorService({
          message: 'Invalid Credentials',
          status,
          type: 'Opentok',
          error,
        });
      case 1013:
      case 1554:
      case 1600:
      case 1605:
      case 2000:
      default:
        return new ErrorService({
          message: 'Unknown Error Occurred',
          status: 500,
          type: 'Opentok',
          error,
        });
    }
  }
}

export default ErrorService;
