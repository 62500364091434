var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-people-wrapper"},[_c('div',{staticClass:"avatar flex-center",class:_vm.getColor},[_vm._v(" "+_vm._s(_vm.getInitials(_vm.participant.name))+" ")]),_c('div',{staticClass:"name flex-center"},[_vm._v(_vm._s(_vm.getName(_vm.participant)))]),(
      _vm.user.role === 'moderator' ||
        _vm.user.role === 'comoderator' ||
        _vm.room.connection.connectionId ==
          _vm.participant.connection.connectionId
    )?_c('div',{staticClass:"actions flex-center"},[_c('button',{staticClass:"info",on:{"click":function($event){return _vm.changeAudio(
          _vm.participant,
          _vm.participant.connection.connectionId
        )}}},[(_vm.participant.hasAudio)?_c('i',{staticClass:"bi bi-mic icon",class:[_vm.participant.isSpeaking ? 'people-speaking' : '']}):_vm._e(),(!_vm.participant.hasAudio)?_c('i',{staticClass:"bi bi-mic-mute-fill icon red"}):_vm._e()]),_c('button',{staticClass:"videocam",on:{"click":function($event){return _vm.changeVideo(
          _vm.participant,
          _vm.participant.connection.connectionId
        )}}},[(_vm.participant.hasVideo)?_c('i',{staticClass:"bi bi-camera-video icon"}):_vm._e(),(!_vm.participant.hasVideo)?_c('i',{staticClass:"bi bi-camera-video-off-fill icon red"}):_vm._e()]),(
        _vm.user.role === 'moderator' || _vm.user.role === 'comoderator'
      )?_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('button',{staticClass:"button is-text",attrs:{"slot":"trigger"},slot:"trigger"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("More")]),_c('i',{staticClass:"bi bi-chevron-down",staticStyle:{"font-size":"12px","margin-left":"2px"}})]),(_vm.participant.userRole !== 'moderator')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.changeUserRole({
            connectionId: _vm.participant.connection.connectionId,
            role: 'moderator',
          })}}},[_vm._v("Make Host")]):_vm._e(),(_vm.participant.userRole !== 'comoderator')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.changeUserRole({
            connectionId: _vm.participant.connection.connectionId,
            role: 'comoderator',
          })}}},[_vm._v("Make Co-Host")]):_vm._e(),(_vm.participant.userRole !== 'publisher')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.changeUserRole({
            connectionId: _vm.participant.connection.connectionId,
            role: 'publisher',
          })}}},[_vm._v("Make Presenter")]):_vm._e(),(_vm.participant.userRole !== 'subscriber')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.changeUserRole({
            connectionId: _vm.participant.connection.connectionId,
            role: 'subscriber',
          })}}},[_vm._v("Make Attendee")]):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.forceDisconnect()}}},[_vm._v("Remove from meeting")])],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }