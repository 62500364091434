import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './registerServiceWorker';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/style.scss';
import Buefy from 'buefy';
//import Rollbar from 'rollbar';

//import ErrorService from './services/error-service';

/*
Vue.prototype.$rollbar = new Rollbar({
  accessToken: '27c1d6c2ae894e07a722165f90143207',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  environment: 'webinar',
});
*/
const customIconConfig = {
  customIconPacks: {
    uil: {
      sizes: {
        default: 'is-size-4',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1',
      },
      iconPrefix: 'uil-',
      internalIcons: {
        check: 'check',
        information: 'information',
        'check-circle': 'checkmark-circle',
        alert: 'exclamation',
        'alert-circle': 'exclamation-circle',
        'arrow-up': 'arrow-up',
        'chevron-right': 'angle-right',
        'chevron-left': 'angle-left',
        'chevron-down': 'arrow-down',
        eye: 'eye',
        'eye-off': 'eye-slash',
        'menu-down': 'angle-down',
        'menu-up': 'angle-up',
        'close-circle': 'times-circle',
      },
    },
  },
};

Vue.config.productionTip = false;
Vue.use(Buefy, {
  defaultIconPack: 'uil',
});

/*
Vue.config.errorHandler = (error) => {
  error = error.status ? error : ErrorService.onGeneral(error);

  console.log(error);
};*/

let vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

vue.$buefy.config.setOptions(customIconConfig);
