<template>
  <div :key="pageIndex">
    <template v-if="exit"><ExitScreen></ExitScreen></template>
    <template v-else>
      <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
      <template v-if="!isLoading">
        <div class="help-text role-changing" v-if="isRoleChanging">
          <div style="display: inline-block">
            <span v-if="newRole" style="display: block;">
              The host has changed your role
              {{
                newRole === 'moderator'
                  ? 'to moderator'
                  : newRole === 'comoderator'
                  ? 'to co-host'
                  : newRole === 'publisher'
                  ? 'to presenter'
                  : newRole === 'subscriber'
                  ? 'to attendee'
                  : ''
              }}.
            </span>
            <div
              style="display: block;"
              class="lds-dual-ring-role mt-4"
            ></div>
            <span
              class="mt-4 pl-4"
              style="display: block; text-align: center;"
            >
              Please wait...
            </span>
          </div>
        </div>
        <webinar v-else></webinar>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { auth } from '@/services/firebase';
import axios from 'axios';
import OT from '@opentok/client';
import { DialogProgrammatic as Dialog } from 'buefy';
import ErrorService from './../services/error-service';

import Webinar from '@/components/Webinar.vue';
import ExitScreen from '@/components/ExitScreen.vue';

export default {
  name: 'Home',
  data: function() {
    return {
      activeTab: 'people',
      activeScreen: 'screen2',
      isActiveSidebarV: false,
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      fetchEvent: 'event/fetchEvent',
      fetchUser: 'user/fetchCurrent',
      onRoleChange: 'user/onRoleChange',
    }),
    async initOT() {
      try {
        // checking system requirements
        if (OT.checkSystemRequirements() != 1) {
          Dialog.alert({
            message: 'This browser is not compatible',
            confirmText: 'Agree',
          });
        }

        await this.fetchUser(this.$route.query.t);

        auth().onAuthStateChanged(async (user) => {
          console.log('auth changed', user);
          if (!user) {
            const {
              data: { fbToken },
            } = await axios({
              method: 'GET',
              url: `${process.env.VUE_APP_API}/refresh`,
              headers: {
                Authorization: `Bearer ${this.$route.query.t}`,
              },
            }).catch((error) => {
              throw ErrorService.onHttp(error, 'User');
            });

            await auth()
              .signInWithCustomToken(fbToken)
              .catch((error) => {
                throw ErrorService.onFirebase('Invalid Token', error);
              });
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-ex-assign
        error = error.status ? error : ErrorService.onGeneral(error);

        // Deneme için
        Dialog.alert({
          message: error.message,
        });
      } finally {
        this.isLoading = false;
        this.onRoleChange();
      }
    },
  },
  mounted() {
    this.initOT();
  },
  components: {
    Webinar,
    ExitScreen,
  },
  computed: {
    ...mapGetters('user', {
      isRoleChanging: 'isRoleChanging',
      newRole: 'newRole',
    }),
    ...mapState('user', { user: (state) => state.current }),
    ...mapGetters('room', {
      exit: 'exit',
      pageIndex: 'pageIndex',
    }),
    ...mapState('event', { event: (state) => state.current }),
  },
  watch: {
    $route: {
      handler() {
        this.initOT();
      },
    },
  },
};
</script>

<style scoped></style>
