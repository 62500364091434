<template>
  <div class="section-screen-control flex-center">
    <div class="button-group">
      <button
        class="control-button"
        @click="$store.commit('user/setSettingsModal', true)"
      >
        <i class="bi bi-gear icon"></i>
        <span class="control-button-text">Settings</span>
      </button>
      <button
        class="control-button"
        @click="changeAudio()"
        v-if="publisherIsPublishing"
        style="min-width: 60px"
      >
        <i class="bi bi-mic icon" v-if="publisher.stream && publisher.stream.hasAudio"></i>
        <i
          class="bi bi-mic-mute-fill icon red"
          v-if="publisher.stream && !publisher.stream.hasAudio"
        ></i>
        <span class="control-button-text"
          >Mic
          <span v-if="publisher.stream && publisher.stream.hasAudio">On</span>
          <span v-if="publisher.stream && !publisher.stream.hasAudio">Off</span>
        </span>
      </button>
      <button
        class="control-button"
        @click="changeVideo()"
        v-if="publisherIsPublishing"
        style="min-width: 70px"
      >
        <i
          class="bi bi-camera-video-fill icon"
          v-if="publisher.stream.hasVideo"
        ></i>
        <i
          class="bi bi-camera-video-off-fill icon red"
          v-if="!publisher.stream.hasVideo"
        ></i>
        <span class="control-button-text">
          Video
          <span v-if="publisher.stream.hasVideo">On</span>
          <span v-if="!publisher.stream.hasVideo">Off</span>
        </span>
      </button>
      <button class="control-button" @click="shareScreen()">
        <i
          :class="{
            'bi bi-tv-fill icon red': isMeSharing,
            'bi bi-tv-fill icon green': !isMeSharing,
          }"
        ></i>
        <span
          class="control-button-text"
          :style="{ color: isMeSharing ? 'red' : 'green' }"
          >{{ isMeSharing ? 'Stop Sharing' : 'Share Screen' }}
        </span>
      </button>
      <button class="control-button" @click="initPresent()">
        <i
          :class="{
            'bi bi-tv-fill icon red': isMePresent,
            'bi bi-tv-fill icon green': !isMePresent,
          }"
        ></i>
        <span
          class="control-button-text"
          :style="{ color: isMePresent ? 'red' : 'green' }"
          >{{ isMePresent ? 'Stop Presenting' : 'Present' }}</span
        >
      </button>
      <!--
      <button
          class="control-button"
          @click="$store.commit('user/setBackgroundModal', true)"
      >
        <i class="bi bi-gear icon"></i>
        <span class="control-button-text">Background</span>
      </button>
      -->
      <button
          class="control-button"
          @click="$store.commit('user/setLayoutBackgroundModal', true)"
      >
        <i class="bi bi-gear icon"></i>
        <span class="control-button-text">Background</span>
      </button>
    </div>

    <div class="button-group">
      <button
        class="nav-live"
        v-if="
          (user.role === 'moderator' || user.role === 'comoderator') && !isLive
        "
        @click="goLive()"
      >
        Go Live
      </button>

      <button
        class="nav-stop"
        v-if="
          (user.role === 'moderator' || user.role === 'comoderator') && isLive
        "
        @click="stopLive()"
      >
        Stop Live
      </button>

      <button
        class="nav-stop-recording"
        v-if="
          (user.role === 'moderator' || user.role === 'comoderator') &&
            isRecording
        "
        @click="stopRecording()"
      >
        Stop Recording
      </button>

      <button
        class="nav-start-recording"
        v-if="
          (user.role === 'moderator' || user.role === 'comoderator') &&
            !isRecording
        "
        @click="startRecording()"
      >
        Start Recording
      </button>

      <button class="nav-leave" @click="exit()">
        Leave
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'Controls',
  components: {},
  data() {
    return {
      share: false,
      isSharing: false,
      isSettingsModalActive: false,
      participantStream: null,
      shareScreenStyle: {
        isMeSharing: { color: 'red' },
        notMeSharing: { color: 'green' },
      },
    };
  },
  computed: {
    ...mapState('user', { user: (state) => state.current }),
    ...mapGetters('user', {
      publisher: 'publisher',
      publisherIsPublishing: 'publisherIsPublishing',
      isMeSharing: 'isMeSharing',
      isMePresent: 'isMePresent',
    }),
    ...mapGetters('room', {
      screenId: 'screenId',
      isLive: 'isLive',
      isRecording: 'isRecording',
    }),
  },
  watch: {
    publisherIsPublishing(n) {
      if (n === true) {
        this.participantStream = this.publisher.stream;
      }
    },
  },
  methods: {
    ...mapActions({
      initScreenShare: 'user/initScreenShare',
      destroyScreenShare: 'user/destroyScreenShare',
      changeAudioDevice: 'user/changeAudioDevice',
      changeVideoDevice: 'user/changeVideoDevice',
      present: 'user/present',
      destroyPresent: 'user/destroyPresent',
      layoutChange: 'room/changeLayout',
      micOn: 'user/micOn',
      micOff: 'user/micOff',
      videoOn: 'user/videoOn',
      videoOff: 'user/videoOff',
      goLive: 'room/goLive',
      stopLive: 'room/stopLive',
      startRecording: 'room/startRecording',
      stopRecording: 'room/stopRecording',
      exit: 'room/exit',
    }),
    screenShareEnabler: function() {
      this.$store.dispatch('setIsSharing');
    },
    shareScreen: async function() {
      try {
        if (this.isSharing == false) {
          const self = this;
          await this.initScreenShare(function() {
            // callback for screen share stop button of browsers
            self.isSharing = false;
          });
          this.isSharing = true;
          this.layoutChange('screenBig');
        } else {
          await this.destroyScreenShare();
          this.layoutChange('galleryview');
          this.isSharing = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async initPresent() {
      try {
        if (!this.isMePresent) {
          await this.present();
          this.layoutChange('screenBig');
        } else {
          this.destroyPresent();
          this.layoutChange('galleryview');
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeAudio: function() {
      if (this.publisher && this.publisher.stream.hasAudio) {
        this.micOff();
      } else {
        this.micOn();
      }
    },
    changeVideo: function() {
      if (this.publisher.stream.hasVideo) {
        this.videoOff();
      } else {
        this.videoOn();
      }
    },
  },
  mounted() {
    console.log(this.publisherIsPublishing);
  },
};
</script>

<style></style>
