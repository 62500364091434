const getters = {
  current: (state) => state.current,
  publisher: (state) => state.publisher,
  publisherIsReady: (state) => state.publisherIsReady,
  attendeeIsReady: (state) => state.attendeeIsReady,
  publisherIsPublishing: (state) => state.publisherIsPublishing,
  screenSharePublisher: (state) => state.screenSharePublisher,
  presenterPublisher: (state) => state.presenterPublisher,
  isMeSharing: (state) => state.isMeSharing,
  isMePresent: (state) => state.isMePresent,
  isRoleChanging: (state) => state.isRoleChanging,
  isGoingLive: (state) => state.isGoingLive,
  isSettingsModalActive: (state) => state.isSettingsModalActive,
  isBackgroundModalActive: (state) => state.isBackgroundModalActive,
  isLayoutBackgroundModalActive: (state) => state.isLayoutBackgroundModalActive,
  newRole: (state) => state.newRole,
};

export { getters };
