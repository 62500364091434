/* eslint-disable no-unused-vars */
import OT from '@opentok/client';
import axios from 'axios';
import { DialogProgrammatic as Dialog } from 'buefy';

import {
  database,
  rtdbTimestamp,
  db,
  timestamp,
} from '../../../services/firebase';
import { otEventListener } from '../../../utils/ot-event-listener';
import { subscriberEventListener } from '../../../utils/subscriber-event-listener';
import ErrorService from '../../../services/error-service';
import { resetState } from '../../index';
import router from '../../../router';

// kullanıcı connect olduğunda realtimedb'deki attendee kaydını oluştur
const sendAttendeeDataToRealtimeDb = async (
  user,
  eventId,
  sessionId,
  connectionId,
) => {
  const docRef = database.ref(`event/${eventId}/${sessionId}/${connectionId}`);
  const data = {
    ...user,
    metrics: { startAt: rtdbTimestamp },
    status: 'connected',
    createdAt: rtdbTimestamp,
  };

  await docRef.set(data);
};

// kullanıcı connect olduğunda event'teki attendeecount field'ını güncelle
const updateAttendeeCount = async (eventId) => {
  const event = (await db.doc(`event/${eventId}`).get()).data();
  const { connectionCount = null } = event;
  const updatedCount = connectionCount ? connectionCount + 1 : 1;

  await db.doc(`event/${eventId}`).update({
    connectionCount: updatedCount,
    updatedAt: timestamp(),
  });
};

const actions = {
  // event ve user bilgileriyle room'a bağlanma ve event listener init etme
  initCurrent: async ({ commit, rootState, dispatch, state }, payload) => {
    try {
      let {
        event: { current: currEvent, account, connectionCount },
        user: { current: currUser },
      } = rootState;
      let { connections } = state;
      let session = OT.initSession(currUser.otKey, currEvent.otSessionId);
      const { webinarCapacity } = account;

      // eğer eldeki connection sayısı webinar'ın kapasitesini aşıyorsa
      // kullanıcının connect olmasına izin verme
      if (
        webinarCapacity &&
        webinarCapacity.attendee &&
        connectionCount + 1 > webinarCapacity.attendee
      ) {
        return Dialog.alert({
          message: 'Connection Capacity Is Exceeded',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        });
      }

      otEventListener(session, commit, dispatch, rootState);

      await new Promise((resolve, reject) => {
        if (session.currentState === 'disconnected') {
          const { role, otmt, otat } = currUser;

          if (!otmt || !otat) {
            return reject('Opentok token does not exist');
          }

          const otToken =
            role === 'publisher' ||
            role === 'moderator' ||
            role === 'comoderator'
              ? otmt
              : otat;

          session.connect(otToken, async (err) => {
            if (err) {
              return reject(err);
            }

            const { token, ...restOf } = currUser;
            const {
              connection: { connectionId },
            } = session;

            // current session id ile beraber attendee kaydını oluştur
            if (currEvent.currentSession) {
              await sendAttendeeDataToRealtimeDb(
                restOf,
                currEvent._id,
                currEvent.currentSession,
                connectionId,
              );
            }

            await updateAttendeeCount(currEvent._id);

            return resolve('Connected to the session');
          });
        } else {
          return resolve('Already Connected to the session');
        }
      });

      // connect olduktan sonra tekrar kontrol et, kapasite aşılmışsa disconnect et
      if (
        webinarCapacity &&
        webinarCapacity.attendee &&
        connections.length > webinarCapacity.attendee
      ) {
        session.disconnect();

        return Dialog.alert({
          message: 'Connection Capacity Is Exceeded',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        });
      }

      commit('setCurrent', session);
    } catch (error) {
      console.log(error);
      throw ErrorService.onOpenTok(error);
    }
  },
  // room'a bağlı herhangi bir stream'in prop'u değiştiğinde günceller (audio/video kapandı açıldı vs)
  onStreamPropertyChange: ({ commit }, payload) => {
    commit('onStreamPropertyChange', payload);
  },
  // room'daki stream'lere bağlanma işlemi, component mount edildiğinde çağrılabilir
  subscribeStreams: ({ state }, payload) => {
    let { current, streams, subscribeProps } = state;

    if (streams.length > 0) {
      streams.forEach((stream) => {
        let subscriber = current.subscribe(
          stream,
          subscribeProps.target,
          {
            ...subscribeProps.style,
          },
          (error) => {
            if (error) {
              console.log(error);

              throw ErrorService.onOpenTok(error);
            }
          },
        );

        //subscriberEventListener(subscriber);
      });
    }
  },
  // bir stream oluşturulduğunda, eğer kullanıcı izler durumdaysa (isWatched: true, yani bir video sayfasındaysa)
  // event listener'dan gelen bilgiye göre subscribe olma işlemi
  streamCreated: ({ commit, state }, payload) => {
    if (payload.videoType == 'screen') {
      commit('screenId', payload.id);
    }
    commit('addStream', payload);
  },
  streamDestroyed: ({ commit }, payload) => {
    commit('removeStream', payload);
    commit('removeVideo', payload);
  },

  addVideo: ({ commit }, payload) => {
    commit('addVideo', payload);
  },
  // publisher'ı destroy etme, sayfadan ayrılırken beforedestroy fonksiyonunda kullanılabilir
  destroyPublisher: ({ commit, state }, payload) => {
    let { current } = state;

    if (payload) {
      current.unpublish(payload);
    }
  },
  // bir stream'i unpublish yapmaya zorlama
  forceUnpublish: ({ state }, payload) => {
    let { streams, current } = state;
    let stream;

    for (let i = 0; i < streams.length; i++) {
      if (payload === streams[i].connection.connectionId) {
        stream = streams[i];
      }
    }

    current.forceUnpublish(stream);
  },
  // bir bağlantıyı disconnect etme
  forceDisconnect: ({ state }, payload) => {
    let { connections, current } = state;
    let connection;

    for (let i = 0; i < connections.length; i++) {
      if (payload === connections[i].connection.connectionId) {
        connection = connections[i].connection;
      }
    }

    current.forceDisconnect(connection);
  },
  // bir connection'ı mute etme
  muteConnection: ({ state }, payload) => {
    let { connections, current } = state;
    let connection;

    for (let i = 0; i < connections.length; i++) {
      if (payload === connections[i].connection.connectionId) {
        connection = connections[i].connection;
      }
    }

    current.signal(
      {
        type: 'audio',
        to: connection,
        data: false,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  goLive: async ({ state, rootState }, payload) => {
    let { current } = state;
    const {
      event: { current: currentEvent },
      user: {
        current: { token },
      },
    } = rootState;

    // database'deki event statüsünü güncelleme
    await axios({
      method: 'PATCH',
      url: `${process.env.VUE_APP_API}/event/${currentEvent._id}`,
      data: { status: 'live' },
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => {
      throw ErrorService.onHttp(err, 'Event');
    });

    current.signal(
      {
        type: 'golive',
        data: true,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  stopLive: async ({ state, rootState }, payload) => {
    let { current } = state;
    const {
      event: { current: currentEvent },
      user: {
        current: { token },
      },
    } = rootState;

    // database'deki event statüsünü güncelleme
    await axios({
      method: 'PATCH',
      url: `${process.env.VUE_APP_API}/event/${currentEvent._id}`,
      data: { status: 'upcoming' },
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => {
      throw ErrorService.onHttp(err, 'Event');
    });

    current.signal(
      {
        type: 'golive',
        data: false,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  startRecording: async ({ state, rootState }, payload) => {
    let { current } = state;
    const {
      event: { current: currentEvent },
      user: {
        current: { token },
      },
    } = rootState;

    // database'deki event statüsünü güncelleme
    await axios({
      method: 'POST',
      url: `${process.env.VUE_APP_API}/archive/start`,
      data: { eventId: currentEvent._id },
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => {
      throw ErrorService.onHttp(err, 'Event');
    });

    current.signal(
      {
        type: 'recording',
        data: true,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  stopRecording: async ({ state, rootState }, payload) => {
    let { current } = state;
    const {
      event: { current: currentEvent },
      user: {
        current: { token },
      },
    } = rootState;

    // database'deki event statüsünü güncelleme
    await axios({
      method: 'POST',
      url: `${process.env.VUE_APP_API}/archive/stop`,
      data: { eventId: currentEvent._id },
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => {
      throw ErrorService.onHttp(err, 'Event');
    });

    current.signal(
      {
        type: 'recording',
        data: false,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  // unmute
  unMuteConnection: ({ state }, payload) => {
    let { connections, current } = state;
    let connection;

    for (let i = 0; i < connections.length; i++) {
      if (payload === connections[i].connection.connectionId) {
        connection = connections[i].connection;
      }
    }

    current.signal(
      {
        type: 'audio',
        to: connection,
        data: true,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  // connection'ı publish ettirme (bunu ve unpublish etmeyi nerede kullanırız bilmiyorum ama ekledim)
  publishConnection: ({ state }, payload) => {
    let { connections, current } = state;
    let connection;

    for (let i = 0; i < connections.length; i++) {
      if (payload === connections[i].connection.connectionId) {
        connection = connections[i].connection;
      }
    }

    current.signal(
      {
        type: 'video',
        to: connection,
        data: true,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  unPublishConnection: ({ state }, payload) => {
    let { connections, current } = state;
    let connection;

    for (let i = 0; i < connections.length; i++) {
      if (payload === connections[i].connection.connectionId) {
        connection = connections[i].connection;
      }
    }

    current.signal(
      {
        type: 'video',
        to: connection,
        data: false,
      },
      function(error) {
        if (error) {
          console.log({ error });

          throw ErrorService.onOpenTok(error);
        } else {
          console.log('Signal successfully sent.');
        }
      },
    );
  },
  setCurrentSpeaker: ({ commit, state }, payload) => {
    commit('currentSpeaker', payload);
  },
  setScreenId: ({ commit, state }, payload) => {
    commit('screenId', payload);
  },
  // bir kullanıcı izleme sayfalarından birine geldiğinde, bu action çağrılarak true hale getirilir
  // yanında subscribeProp'larıyla beraber
  // subscribeProps: {
  //   target: 'media-layout',
  //   style: {
  //     insertMode: 'append',
  //     style: { nameDisplayMode: 'on' },
  //     fitMode: 'contain',
  //   },
  // },
  // gibi
  isWatching: ({ commit }, payload) => {
    commit('isWatching', payload);
  },
  // kullanıcı rolünü değiştirme işlemi
  changeUserRole: async ({ state, rootState }, { connectionId, role }) => {
    let { current, connections } = state;
    let {
      user: {
        current: { token },
      },
      event: {
        current: { accountId },
      },
      room: { streams },
    } = rootState;
    let connection;
    let _id;

    const account = (await db.doc(`account/${accountId}`).get()).data();
    const {
      webinarCapacity: { presenter },
    } = account;

    if (
      (role === 'moderator' ||
        role === 'comoderator' ||
        role === 'publisher') &&
      streams.length + 2 > presenter
    ) {
      return Dialog.alert({
        message: 'Presenter Capacity Is Exceeded',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    } else {
      for (let i = 0; i < connections.length; i++) {
        if (connectionId === connections[i].connection.connectionId) {
          connection = connections[i].connection;
          _id = connections[i]._id;
        }
      }

      await axios({
        method: 'PATCH',
        url: `${process.env.VUE_APP_API}/registration/${_id}`,
        data: { role },
        headers: { Authorization: `Bearer ${token}` },
      }).catch((err) => {
        throw ErrorService.onHttp(err, 'User');
      });

      current.signal(
        {
          type: 'user',
          to: connection,
          data: role,
        },
        function(error) {
          if (error) {
            console.log({ error });

            throw ErrorService.onOpenTok(error);
          } else {
            console.log('Signal successfully sent.');
          }
        },
      );
    }
  },
  changeLayout: ({ state, commit }, payload) => {
    let { current } = state;

    current.signal({ type: 'layout', data: payload }, function(error) {
      if (error) {
        console.log({ error });

        throw ErrorService.onOpenTok(error);
      } else {
        console.log('Signal successfully sent');
      }
    });
    commit('changeLayout', payload);
  },
  onLayoutChange: ({ commit }, payload) => {
    commit('changeLayout', payload);
  },
  setIsSpeaking: ({ commit }, payload) => {
    commit('setIsSpeaking', payload);
  },
  setScreenShared: ({ commit }, payload) => {
    commit('setScreenShared', payload);
  },
  exit: ({ commit, state }) => {
    const { pageIndex } = state;

    commit('endSession');
    resetState();
    commit('exit', true);
    commit('setPageIndex', pageIndex + 1);
  },
  goBack: ({ commit, state, root }) => {
    const { pageIndex } = state;

    commit('exit', false);
    commit('setPageIndex', pageIndex + 1);

    router.go();
  },
};

export { actions };
