import Vue from 'vue';
import VueRouter from 'vue-router';

import Base from '@/views/Base.vue';
import Landing from '@/views/Landing.vue';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '/:slug',
    component: Base,
    children: [
      {
        path: '',
        component: Home,
      },
      /*
      {
        path: '/w',
        component: Webinar,
      },
      */
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
