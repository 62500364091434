const getters = {
  current: (state) => state.current,
  connections: (state) => state.connections,
  streams: (state) => state.streams,
  videoList: (state) => state.videoList,
  isWatched: (state) => state.isWatched,
  subscribeProps: (state) => state.subscribeProps,
  currentSpeaker: (state) => state.currentSpeaker,
  screenId: (state) => state.screenId,
  layout: (state) => state.layout,
  isLive: (state) => state.isLive,
  isRecording: (state) => state.isRecording,
  pageIndex: (state) => state.pageIndex,
  exit: (state) => state.exit,
  newMessages: (state) => state.newMessages,
};

export { getters };
