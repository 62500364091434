const state = {
  current: null,
  publisher: null,
  publisherIsReady: false,
  attendeeIsReady: false,
  publisherIsPublishing: false,
  screenSharePublisher: null,
  presenterPublisher: null,
  pending: false,
  isMeSharing: false,
  isMePresent: false,
  isRoleChanging: false,
  isGoingLive: false,
  isSettingsModalActive: false,
  isBackgroundModalActive: false,
  isLayoutBackgroundModalActive: false,
  newRole: null,
};

export { state };
