const mutations = {
  setSettingsModal: (state, payload) => {
    state.isSettingsModalActive = payload;
  },
  setBackgroundModal: (state, payload) => {
    state.isBackgroundModalActive = payload;
  },
  setLayoutBackgroundModal: (state, payload) => {
    state.isLayoutBackgroundModalActive = payload;
  },
  setCurrent: (state, payload) => {
    state.current = payload;
  },
  onMuted: (state) => {
    let { publisher } = state;

    if (publisher) {
      publisher.publishAudio(false);
    }

    state.publisher = publisher;
  },
  onUnMuted: (state) => {
    let { publisher } = state;

    if (publisher) {
      publisher.publishAudio(true);
    }

    state.publisher = publisher;
  },
  onPublishVideo: (state) => {
    let { publisher } = state;

    if (publisher) {
      publisher.publishVideo(true);
    }

    state.publisher = publisher;
  },
  onUnPublishVideo: (state) => {
    let { publisher } = state;

    if (publisher) {
      publisher.publishVideo(false);
    }

    state.publisher = publisher;
  },
  changeAudioDevice: (state, payload) => {
    let { publisher } = state;

    publisher.setAudioSource(payload);

    state.publisher = publisher;
  },
  setPublisher: (state, payload) => {
    state.publisher = payload;
  },
  setPublisherIsReady: (state, payload) => {
    state.publisherIsReady = payload;
  },
  setAttendeeStatus: (state, payload) => {
    state.attendeeIsReady = payload;
  },
  setPublisherIsPublishing: (state, payload) => {
    state.publisherIsPublishing = payload;
  },
  setScreenSharePublisher: (state, payload) => {
    state.screenSharePublisher = payload;
  },
  setPresenterPublisher: (state, payload) => {
    state.presenterPublisher = payload;
  },
  onScreenShare: (state, payload) => {
    state.isMeSharing = payload;
  },
  onPresent: (state, payload) => {
    state.isMePresent = payload;
  },
  destroyPublisher: (state, payload) => {
    state.publisher = payload;
  },
  changeStream: (state, payload) => {
    state.publisher = payload;
  },
  setPending: (state, payload) => {
    state.pending = payload;
  },
  setRoleChanging: (state, payload) => {
    state.isRoleChanging = payload;
  },
  setIsGoingLive: (state, payload) => {
    state.isGoingLive = payload;
  },
  setNewRole: (state, payload) => {
    state.newRole = payload;
  },
  setCurrentUserRole: (state, payload) => {
    state.current.role = payload;
  },
};

export { mutations };
