/* eslint-disable no-unused-vars */
import axios from 'axios';
import OT from '@opentok/client';
import { DialogProgrammatic as Dialog } from 'buefy';

import { auth } from '../../../services/firebase';
import ErrorService from '../../../services/error-service';
import image from '../../../assets/3.png';

const actions = {
  // url'deki query vasıtasıyla ilgili kullanıcıyı çağırma, auth etme ve store'a kaydetme
  fetchCurrent: async ({ commit, dispatch }, payload) => {
    const {
      data: { registration, event },
    } = await axios({
      method: 'GET',
      url: `${process.env.VUE_APP_API}/registration/me`,
      headers: { Authorization: `Bearer ${payload}` },
    }).catch((err) => {
      throw ErrorService.onHttp(err, 'User');
    });

    await auth()
      .signInWithCustomToken(registration.fbToken)
      .catch((err) => {
        throw ErrorService.onFirebase('Problem logging in with Firebase', err);
      });

    commit('setCurrent', { ...registration });
    await dispatch('event/fetchEvent', event, { root: true });
  },

  // eğer elimizdeki kullanıcıya mute etme sinyali gelmişse, publisher'ın publishAudio prop'unu false yap
  onMuted: ({ commit, state }) => {
    let { publisher, pending } = state;

    if (publisher && !pending) {
      commit('onMuted');
    }
  },
  // eğer elimizdeki kullanıcıya unmute etme sinyali gelmişse, publisher'ın publishAudio prop'unu true yap
  onUnMuted: ({ commit, state }) => {
    let { publisher, pending } = state;

    if (publisher && !pending) {
      commit('setPending', true);

      Dialog.alert({
        message: 'Moderator wants to unmute your audio stream',
        canCancel: true,
        confirmText: 'Agree',
        cancelText: 'Disagree',
        onConfirm: () => {
          commit('onUnMuted');
          commit('setPending', false);
        },
        onCancel: () => {
          commit('setPending', false);
        },
      });
    }
  },
  onPublishVideo: ({ commit, state }) => {
    let { publisher, pending } = state;

    if (publisher && !pending) {
      commit('setPending', true);

      Dialog.alert({
        message: 'Moderator wants to publish your video stream',
        canCancel: true,
        confirmText: 'Agree',
        cancelText: 'Disagree',
        onConfirm: () => {
          commit('onPublishVideo');
          commit('setPending', false);
        },
        onCancel: () => {
          commit('setPending', false);
        },
      });
    }
  },
  onUnPublishVideo: ({ commit, state }) => {
    let { publisher, pending } = state;

    if (publisher && !pending) {
      commit('onUnPublishVideo');
    }
  },

  // Kullanıcı input/output deviceları istiyoruz
  getDeviceList: async ({ commit, state }, callback) => {
    const stream = await OT.getUserMedia({});
    OT.getDevices((error, devices) => {
      if (error) {
        callback(false);
      }

      const audioInputs = devices.filter(
        (device) => device.kind === 'audioInput',
      );
      const videoInputs = devices.filter(
        (device) => device.kind === 'videoInput',
      );
      const [videoSource] = stream.getVideoTracks();
      callback({
        audioInputs,
        videoInputs,
        videoSource,
      });
    });
  },

  // audio aygıtını değiştirme
  changeAudioDevice: async ({ commit, state }, payload) => {
    try {
      let { publisher } = state;
      const stream = await OT.getUserMedia({});
      const [audioSource] = stream.getAudioTracks();

      // şu anki audio track'i source olarak ekle
      publisher.setAudioSource(audioSource);

      let audioInputs;
      let currentIndex = 0;

      await new Promise((resolve, reject) => {
        OT.getDevices((error, devices) => {
          if (error) {
            return reject(error);
          }

          audioInputs = devices.filter(
            (device) => device.kind === 'audioInput',
          );

          // şu anki audio device'ın index'ini bul
          audioInputs.forEach((device, idx) => {
            if (device.label === publisher.getAudioSource().label) {
              currentIndex = idx;
            }
          });

          return resolve();
        });
      });

      // ardından bir sonraki index'teki audio device'a geçiş yap
      currentIndex += 1;
      let deviceId = audioInputs[currentIndex % audioInputs.length].deviceId;

      commit('changeAudioDevice', deviceId);
    } catch (error) {
      console.log(error);

      throw ErrorService.onOpenTok(error);
    }
  },
  // video aygıtını değiştirme
  changeVideoDevice: async ({ state }, payload) => {
    try {
      let { publisher } = state;

      await new Promise((resolve, reject) => {
        // eldeki video device'ları al
        OT.getDevices((error, devices) => {
          if (error) {
            console.log({ error });

            return reject(error);
          }

          console.log({ devices });
          return resolve();
        });
      });

      let device = await publisher.cycleVideo();

      console.log({ ['New Video Device']: device });
    } catch (error) {
      console.log(error);

      throw ErrorService.onOpenTok(error);
    }
  },
  // yayın oluşturma
  publish: async ({ state, rootState, commit }) => {
    try {
      let { publisher, current } = state;
      let currRoom = rootState.room.current;

      if (currRoom && currRoom.capabilities.publish == 1 && !publisher) {
        let publisherEl;

        publisherEl = await OT.initPublisher({
          insertDefaultUI: false,
          name: current.name,
          resolution: '1280x720',
        });

        console.log({ publisher: publisherEl });

        commit('setPublisher', publisherEl);
        // publisherEventListener(publisherEl, dispatch);
        return publisherEl;
      }
    } catch (error) {
      console.log(error);
      throw ErrorService.onOpenTok(error);
    }
  },
  // screen publish etme
  initScreenShare: async ({ commit, state, rootState, dispatch }, payload) => {
    try {
      let {
        current: { name },
      } = state;
      let {
        room: { current: currRoom },
      } = rootState;

      await new Promise((resolve, reject) => {
        OT.checkScreenSharingCapability((response) => {
          if (!response.supported || response.extensionRegistered === false) {
            return reject('Screen sharing not supported');
          } else if (response.extensionInstalled === false) {
            return reject('Browser requires extension');
          } else {
            return resolve();
          }
        });
      });

      let screenSharePublisher = OT.initPublisher(
        'screenshare',
        {
          name: `${name}'s screen`,
          insertMode: 'append',
          videoSource: 'screen',
          style: { nameDisplayMode: 'on' },
          width: '100%',
          height: '100%',
        },
        (error) => {
          if (error) {
            console.log(error);

            throw error;
          }
        },
      );

      // listener for Stream destroy on screen share
      screenSharePublisher.on('destroyed', function() {
        payload();
        commit('onScreenShare', false);
      });
      await new Promise((resolve, reject) => {
        currRoom.publish(screenSharePublisher, (error) => {
          if (error) {
            console.log(error);

            return reject(error);
          }

          return resolve();
        });
      });

      commit('setScreenSharePublisher', screenSharePublisher);
      commit('onScreenShare', true);
      dispatch(
        'room/setScreenShared',
        {
          connectionId: screenSharePublisher.stream.connection.connectionId,
          screenShared: true,
        },
        { root: true },
      );
    } catch (error) {
      console.log(error);

      throw ErrorService.onOpenTok(error);
    }
  },
  // screenshare publisher'ını yok etme
  destroyScreenShare: ({ commit, state, dispatch }) => {
    let { screenSharePublisher } = state;
    let { connectionId } = screenSharePublisher.stream.connection;

    screenSharePublisher.destroy();
    commit('onScreenShare', false);
    commit('setScreenSharePublisher', null);
    dispatch(
      'room/setScreenShared',
      {
        connectionId,
        screenShared: false,
      },
      { root: true },
    );
  },
  destroyPresent: ({ commit, state, dispatch }) => {
    const { presenterPublisher } = state;

    presenterPublisher.destroy();
    commit('setPresenterPublisher', null);
    commit('onPresent', false);
  },
  // video background change functions not using right now
  filterVideo: async ({commit, state, dispatch, rootState }, payload) => {
    console.log('----', payload)

    const {
      room: { current: currRoom },
    } = rootState;


    const publishPromise = new Promise((resolve, reject) => {
      const publisher = OT.initPublisher('publisher', payload, function initComplete(err) {
        if (err) {
          console.log('----------errr');
          reject(err)
        } else {
          console.log('yessss');
          resolve(publisher)
        }
      });

      publisher.on('destroyed', function destroyed() {
        // When the publisher is destroyed we cleanup
        console.log('----------destroyed');
      });

      publisher.on('streamCreated', function created() {
        // We use this for testing so we know when we are publishing successfully
        publisher.element.dataset.streamCreated = true;
        console.log('----------streamCreated');
      });
    });

    publishPromise.then((publisher) => {
      commit('setPublisherIsPublishing', false);
      commit('setPublisherIsReady', false);
      dispatch('destroyPublisher');
      currRoom.publish(publisher, (error) => {
        if (error) {
          console.log(error);
          return reject(error);
        }
        commit('setPublisher', publisher);
        commit('setPublisherIsPublishing', true);
        commit('setPublisherIsReady', true);
        return resolve();
      });
    });
  },
  present: async ({ commit, state, dispatch, rootState }, payload) => {
    console.log('girdi');
    const {
      current: { name },
    } = state;
    const {
      room: { current: currRoom },
    } = rootState;
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');

    img.src = image;
    canvas.width = 640;
    canvas.height = 360;

    const ctx = canvas.getContext('2d');

    await new Promise((res) => {
      img.addEventListener('load', () => {
        res();
      });
    });

    (function loop() {
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        img.width * (canvas.width / img.width),
        img.height * (canvas.height / img.height),
      );
      setTimeout(loop, 1000); // drawing at 30fps
    })();

    const publisherEl = await OT.initPublisher(
      {
        insertDefaultUI: false,
        name: name,
        videoSource: canvas.captureStream(1).getVideoTracks()[0],
        audioSource: null,
        resolution: '1280x720',
      },
      (err) => {
        console.log(err);
      },
    );

    publisherEl.on('destroyed', function() {
      commit('onPresent', false);
    });
    await new Promise((resolve, reject) => {
      currRoom.publish(publisherEl, (error) => {
        if (error) {
          console.log(error);

          return reject(error);
        }

        return resolve();
      });
    });

    commit('setPresenterPublisher', publisherEl);
    commit('onPresent', true);
  },
  // publisher'ı yok etme
  destroyPublisher: ({ commit, state, rootState, dispatch }) => {
    let { publisher } = state;
    let {
      room: { current },
    } = rootState;

    // kullanıcı component'tan ayrılacağı vakit, eğer publish özelliğine sahipse unpublish olmasını sağla
    if (current && current.capabilities.publish == 1) {
      dispatch('room/destroyPublisher', publisher, { root: true });
      commit('destroyPublisher', null);
    }
  },
  setPublisherIsReady: ({ commit }, payload) => {
    commit('setPublisherIsReady', payload);
  },
  setPublisherIsPublishing: ({ commit }, payload) => {
    commit('setPublisherIsPublishing', payload);
  },
  // kullanıcı mic ve video kontrolleri (kendi için)
  micOn: ({ commit }) => {
    commit('onUnMuted');
  },
  micOff: ({ commit }) => {
    commit('onMuted');
  },
  videoOn: ({ commit }) => {
    commit('onPublishVideo');
  },
  videoOff: ({ commit }) => {
    commit('onUnPublishVideo');
  },
  onRoleChange: ({ commit }) => {
    commit('setRoleChanging', false);
  },
  isGoingLive: ({ commit }) => {
    commit('setIsGoingLive', true);
  },
  goingLiveEnd: ({ commit }) => {
    commit('setIsGoingLive', false);
  },
};

export { actions };
