<template>
  <div class="layout">
    <div
      class="meeting-wrapper"
      :class="{ mobile: isActiveSidebarV }"
      v-if="!isLoading && (publisherIsReady || attendeeIsReady)"
    >
      <div class="meeting-wrapper-l">
        <Header @changeScreen="onChangeScreen" />
        <div class="section-screen">
          <template
              v-if="
              (!isLive && !isGoingLive || isLive && isGoingLive) &&
          currentUser.role !== 'moderator' &&
          currentUser.role !== 'publisher' &&
          currentUser.role !== 'comoderator'"
          >
            <div class="help-text">
              <div style="display: inline-block">
          <span style="display: block;">
            Event not started yet
          </span>
              </div>
            </div>
          </template>
          <template v-else>
            <MediaLayout :sidebar="isActiveSidebarV" />
          </template>
        </div>
      </div>
      <div class="meeting-wrapper-r">
        <button class="right-collapse" @click="isActiveSidebar">
          <img src="@/img/nav-arrow.svg" />
        </button>

        <div class="section-sidebar-nav">
          <ul>
            <li :class="{ active: activeTab == 'q&a' }">
              <button @click="activeTab = 'q&a'">
                Q&A
              </button>
            </li>
            <li :class="{ active: activeTab == 'chat' }">
              <button @click="activeTab = 'chat'">
                Chat
              </button>
              <div v-if="newMessages != 0" class="message-badge">
                {{ newMessages }}
              </div>
            </li>
            <li
              v-if="
                currentUser.role == 'moderator' ||
                  currentUser.role == 'publisher' ||
                  currentUser.role == 'comoderator'
              "
              :class="{ active: activeTab == 'people' }"
              @click="activeTab = 'people'"
            >
              <button>People</button>
            </li>
          </ul>
        </div>

        <div v-show="activeTab === 'q&a'">
          <q-n-a />
        </div>
        <div v-show="activeTab === 'chat'">
          <Chat ref="chatTab" />
        </div>
        <div
          v-show="
            activeTab === 'people' &&
              (currentUser.role == 'moderator' ||
                currentUser.role == 'publisher' ||
                currentUser.role == 'comoderator')
          "
        >
          <People />
        </div>
      </div>
    </div>
    <b-modal
      v-model="settingsModalModel"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      @close="$store.commit('user/setSettingsModal', false)"
    >
      <template #default="props">
        <AVSettings @close="props.close" />
      </template>
    </b-modal>
    <b-modal
      v-model="isJoinModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default="props">
        <AttendeeJoinModal
            @close="props.close"
            has-modal-card
            :can-cancel="false"
            width="640"
            v-if="currentUser.role == 'subscriber'"
        ></AttendeeJoinModal>
        <PresenterJoinModal
            @close="props.close"
            has-modal-card
            :can-cancel="false"
            width="640"
            v-if="
            currentUser.role == 'moderator' ||
              currentUser.role == 'publisher' ||
              currentUser.role == 'comoderator'
          "
        ></PresenterJoinModal>
      </template>
    </b-modal>
    <b-modal
        v-model="backgroundModalModel"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
        @close="$store.commit('user/setBackgroundModal', false)"
    >
      <template #default="props">
        <BackgroundChanger @close="props.close"></BackgroundChanger>
      </template>
    </b-modal>
    <b-modal
        v-model="layoutBackgroundModalModel"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
        @close="$store.commit('user/setLayoutBackgroundModal', false)"
    >
      <template #default="props">
        <LayoutBackgroundChanger @close="props.close"></LayoutBackgroundChanger>
      </template>
    </b-modal>
    <div class="is-going-live" v-if="isGoingLive">
      <div class="going-live-container">
        <div>
          <h3>Going Live</h3>
          <div style="display: block;" class="lds-dual-ring">
            <div class="rd-1"></div>
            <div class="rd-2"></div>
            <div class="rd-3"></div>
            <div class="rd-4"></div>

            <div class="rb-1"></div>
            <div class="rb-2"></div>
            <div class="rb-3"></div>
            <div class="rb-4"></div>
            <h3 style="z-index: 4;">{{ countDown }}</h3>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Header from '@/components/layout/Header.vue';
import MediaLayout from '@/components/media/MediaGridLayout.vue';
import AttendeeJoinModal from '@/components/media/AttendeeJoinModal.vue';
import PresenterJoinModal from '@/components/media/PresenterJoinModal.vue';
import Chat from '@/components/tabs/chat/Chat.vue';
import QNA from '@/components/tabs/qna/QNA.vue';
import People from '@/components/tabs/people/People.vue';
import AVSettings from '@/components/layout/AVSettings.vue';
import BackgroundChanger from '@/components/layout/BackgroundChanger.vue';
import LayoutBackgroundChanger from '@/components/layout/LayoutBackgroundChanger.vue';

export default {
  name: 'App',
  data: function() {
    return {
      activeTab: 'chat',
      isActiveSidebarV: false,
      isLoading: true,
      isJoinModalActive: false,
      settingsModalModel: false,
      backgroundModalModel: false,
      layoutBackgroundModalModel: false,
      joinModalModel: false,
      countDown: 5,
    };
  },
  components: {
    Header,
    MediaLayout,
    Chat,
    QNA,
    People,
    AttendeeJoinModal,
    PresenterJoinModal,
    AVSettings,
    BackgroundChanger,
    LayoutBackgroundChanger
  },
  computed: {
    ...mapGetters('user', {
      currentUser: 'current',
      isLive: 'isLive',
      isGoingLive: 'isGoingLive',
      isRoleChanging: 'isRoleChanging',
      isSettingsModalActive: 'isSettingsModalActive',
      isBackgroundModalActive: 'isBackgroundModalActive',
      isLayoutBackgroundModalActive: 'isLayoutBackgroundModalActive',
      publisherIsReady: 'publisherIsReady',
      attendeeIsReady: 'attendeeIsReady',
    }),
    ...mapGetters('room', {
      isLive: 'isLive',
      newMessages: 'newMessages',
    }),
  },
  watch: {
    activeTab(val) {
      if (val == 'chat') {
        this.$store.commit('room/setNewMessageCount', 0);
        this.$refs.chatTab.fixBadge();
      }
    },
    isSettingsModalActive(val) {
      this.settingsModalModel = val;
      if (val) {
        this.changeModalBackground();
      }
    },
    isBackgroundModalActive(val) {
      this.backgroundModalModel = val;
      if (val) {
        this.changeModalBackground();
      }
    },
    isLayoutBackgroundModalActive(val) {
      this.layoutBackgroundModalModel = val;
    },
    isGoingLive(val) {
      const self = this;
      if (val) {
        const myInterval = setInterval(function() {
          self.countDown--;
          if (self.countDown <= 0) {
            clearInterval(myInterval);
            self.goingLiveEnd();
            self.countDown = 5;
          }
        }, 1000);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchEvent: 'event/fetchEvent',
      fetchUser: 'user/fetchCurrent',
      initRoom: 'room/initCurrent',
      goingLiveEnd: 'user/goingLiveEnd',
    }),
    isActiveSidebar: function() {
      this.isActiveSidebarV = !this.isActiveSidebarV;
    },
    // Header - Change screen func
    onChangeScreen(value) {
      this.activeScreen = value;
    }
    // TODO countdown and trigger events
  },
  mounted() {
    let background = window.localStorage.getItem('layoutBackground');
    if (background) {
      document.querySelector('.layout').style.backgroundImage = "url('"+background+"')"
      document.querySelector('body').style.backgroundImage = "url('"+background+"')"
    }
  },
  created() {
    const self = this;
    if (!this.isRoleChanging) {
      try {
        this.initRoom().then(() => {
          self.isJoinModalActive = true;
          window.addEventListener('resize', () => {
            if (window.innerWidth < 900) {
              this.isActiveSidebarV = true;
            }
          });
        });
      } catch (error) {
        console.log('-ee--', error);
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>

<style lang="scss">
  .rd-1 {
    position: absolute;
    display: inline-block;
    width: 98px;
    right: 0px;
    top: 0px;
    height: 98px;
    background-image: conic-gradient(
            rgba(196, 196, 196, 0.5) 360deg,
            rgba(255, 255, 255, 0) 0 360deg
    );
    border-right: 6px solid #fff;
    border-top: 6px solid #fff;
    border-color: #c4c4c4;
    border-radius: 0% 100% 0% 0%;
    z-index: 2;
  }
  .rd-2 {
    position: absolute;
    display: inline-block;
    width: 98px;
    right: 0px;
    bottom: 0px;
    height: 98px;
    background-image: conic-gradient(
            rgba(196, 196, 196, 0.5) 360deg,
            rgba(255, 255, 255, 0) 0 360deg
    );
    border-right: 6px solid #fff;
    border-bottom: 6px solid #fff;
    border-color: #c4c4c4;
    border-radius: 0% 0% 100% 0%;
  }
  .rd-3 {
    position: absolute;
    display: inline-block;
    width: 98px;
    left: 0px;
    bottom: 0px;
    height: 98px;
    background-image: conic-gradient(
            rgba(196, 196, 196, 0.5) 360deg,
            rgba(255, 255, 255, 0) 0 360deg
    );
    border-left: 6px solid #fff;
    border-bottom: 6px solid #fff;
    border-color: #c4c4c4;
    border-radius: 0% 0% 0% 100%;
  }
  .rd-4 {
    position: absolute;
    display: inline-block;
    width: 98px;
    left: 0px;
    top: 0px;
    height: 98px;
    background-image: conic-gradient(
            rgba(196, 196, 196, 0.5) 360deg,
            rgba(255, 255, 255, 0) 0 360deg
    );
    border-left: 6px solid #fff;
    border-top: 6px solid #fff;
    border-color: #c4c4c4;
    border-radius: 100% 0% 0% 0%;
  }

  .rb-1 {
    position: absolute;
    display: inline-block;
    width: 98px;
    right: 0px;
    top: 0px;
    transform-origin: bottom left;
    animation: rb-1 1s linear infinite;
    height: 98px;
    z-index: 3;
    background-color: #4eb070;
  }

  @keyframes rb-1 {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    24% {
      transform: rotate(90deg);
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    50% {
      transform: rotate(0deg);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .rb-2 {
    position: absolute;
    display: inline-block;
    width: 98px;
    right: 0px;
    bottom: 0px;
    transform-origin: top left;
    animation: rb-2 1s linear infinite;
    height: 98px;
    z-index: 1;
    background-color: #4eb070;
  }

  @keyframes rb-2 {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    25% {
      opacity: 1;
      transform: rotate(0deg);
    }
    49% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      opacity: 0;
    }
  }

  .rb-3 {
    position: absolute;
    display: inline-block;
    width: 98px;
    left: 0px;
    bottom: 0px;
    transform: rotate(0deg);
    transform-origin: top right;
    animation: rb-3 1s linear infinite;
    height: 98px;
    z-index: 1;
    background-color: #4eb070;
  }

  @keyframes rb-3 {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    50% {
      opacity: 1;
      transform: rotate(0deg);
    }
    74% {
      transform: rotate(90deg);
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: rotate(0deg);
    }
  }

  .rb-4 {
    position: absolute;
    display: inline-block;
    width: 98px;
    left: 0px;
    top: 0px;
    transform: rotate(0deg);
    transform-origin: bottom right;
    animation: rb-4 1s linear infinite;
    height: 98px;
    z-index: 1;
    background-color: #4eb070;
  }

  @keyframes rb-4 {
    0% {
      opacity: 0;
      transform: rotate(0deg);
    }
    1% {
      opacity: 1;
      transform: rotate(0deg);
    }
    50% {
      opacity: 1;
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(90deg);
    }
  }

.is-going-live {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.16);
  position: fixed !important;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(2px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .going-live-container {
    width: 640px;
    height: 480px;
    background: #4eb070;
    border-radius: 16px;
    > div {
      margin-top: 75px;
    }
    h3 {
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      text-align: center;
    }
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 196px;
  height: 196px;
  margin: auto;
  margin-top: 60px;
  font-size: 20px;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  h3 {
    position: absolute;
    top: 76px;
    left: 88px;
  }
}


@keyframes ring-right-side-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ring-right-side-2 {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ring-left-side-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ring-left-side-1 {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

</style>
