<template>
  <div class="section-miniwrapper">
    <div class="section-chat">
      <div style="position: relative">
        <select v-model="roomType" @change="onChange($event)">
          <option style="color: black" value="all">
            Everyone

          </option>
          <option
            style="color: black"
            v-if="
              currentUser.role === 'moderator' ||
              currentUser.role === 'publisher' ||
              currentUser.role === 'comoderator'
            "
            value="presenter"
          >
            Presenters
          </option>
        </select>
        <div v-if="presenterMessageCount != 0" class="message-badge message-badge-chat">
          {{ presenterMessageCount }}
        </div>
        <div v-if="allMessageCount != 0" class="message-badge message-badge-chat">
          {{ allMessageCount }}
        </div>
      </div>

      <div class="scroll-wrapper">
        <infinite-loading
          v-if="messages.length > 0"
          class="mb-3"
          direction="top"
          spinner="waveDots"
          :distance="5"
          @infinite="infiniteHandler"
          forceUseInfiniteWrapper=".scroll-wrapper"
        >
          <b-notification
            slot="no-more"
            type="is-success is-light"
            :closable="false"
          >
            No more messages.
          </b-notification>
        </infinite-loading>
        <div
          class="message-item"
          v-for="(message, $index) in this.messages.filter(a => a.channel == roomType)"
          :key="message.id"
          :data-num="messages.length - $index"
          v-bind:class="[message.from === user.uid ? 'right' : 'left']"
        >
          <div class="header">
            <div class="name">{{ message.name }}</div>
            <time>{{ timeConverter(message.timestamp) }}</time>
          </div>
          <p>{{ message.message }}</p>
        </div>
      </div>

      <div class="send-input">
        <form @submit.prevent="sendMessage">
          <input type="text" name="message" v-model="message" />
          <button style="background-color: #35db9f; color: white" type="submit">
            Gönder
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { auth, rtdb } from "../../../services/firebase";
import ErrorService from "../../../services/error-service";
import InfiniteLoading from "vue-infinite-loading";
import moment from "moment";

export default {
  name: "Chat",
  data() {
    return {
      messages: [],
      message: "",
      user: auth().currentUser,
      newestId: null,
      isBottom: true,
      roomType: "all",
      allMessageCount: 0,
      presenterMessageCount: 0,
    };
  },
  components: {
    InfiniteLoading
  },
  computed: {
    ...mapGetters("user", {
      currentUser: "current"
    }),
    ...mapGetters('room', {
      newMessages: 'newMessages',
    }),
  },
  mounted: function() {
    this.initMessages();
    this.scrollToBottom();
    this.scrollListenInit();
  },
  methods: {
    fixBadge() {
      console.log('fixBadge', this.roomType);
      if ( this.roomType == "all") {
        this.allMessageCount = 0;
      } else {
        this.presenterMessageCount = 0;
      }
    },
    fetchOldMessages(callback) {
      let webcast = this.$route.params.slug;
      // deneme chat room'undaki messages koleksiyonunun referansını al
      let ref = rtdb.ref(`chat/${webcast}/${this.roomType}`);
      let messagesNew = [];
      return ref
        .endAt(this.messages[0].id)
        .orderByKey()
        .limitToLast(10)
        .once("value")
        .then(snapshot => {
          snapshot.forEach(item => {
            const itemData = item.val();
            itemData.id = item.key;

            if (this.messages.findIndex(v => v.id === itemData.id) == -1) {
              messagesNew.push(itemData);
            }
            //context.commit('addItem', itemData);
            //context.commit('setNewest', itemData.id);
          });
          this.messages = messagesNew.concat(this.messages);
          callback(messagesNew);
        })
        .catch(error => {
          const e = Object.assign(error, {
            message: "Data fetch error"
          });
          throw e;
        });
    },
    initMessages() {
      let webcast = this.$route.params.slug;
      // deneme chat room'undaki messages koleksiyonunun referansını al
      let ref = rtdb.ref(`/chat/${webcast}`);
      let messagesNew = [];
      return ref
        .orderByKey()
        .limitToLast(10)
        .once("value")
        .then(snapshot => {
          snapshot.forEach(item => {
            if( item.key == 'all' || item.key == 'presenter') {

              item.forEach(i => {
                const itemData = i.val();
                itemData.id = i.key;
                itemData.channel = item.key;
                if (this.messages.findIndex(v => v.id === itemData.id) == -1) {
                  messagesNew.push(itemData);
                }
              });
            }
          });
          this.messages = this.messages.concat(messagesNew);
          this.listenAll();
          this.listenPresenter();
          this.isBottom = false;
          self.scrollToBottom();
        })
        .catch(error => {
          const e = Object.assign(error, {
            message: "Data fetch error"
          });
          throw e;
        });
    },
    listenAll() {
      // TODO get call id
      let webcast = this.$route.params.slug;
      let ref = rtdb.ref(`/chat/${webcast}/all`).orderByKey();
      const listenRoom = this.roomType;

      if (this.messages.length > 0) {
        ref = ref.startAt(this.messages[this.messages.length - 1].id);
      }
      ref.on(
        "child_added",
        item => {
          const itemData = item.val();
          itemData.id = item.key;
          itemData.channel = 'all';
          if (this.messages.findIndex(v => v.id === itemData.id) == -1) {
            this.messages.push(itemData);
            if (this.$parent.activeTab != "chat") {
              this.$store.commit("room/newMessageAdded");
              this.allMessageCount++;
            }

            if(this.roomType != 'all'){
              this.allMessageCount++;
            }

            this.scrollToBottom();
          }
        },
        err => {
          console.log("----------", err);
        }
      );
    },
    listenPresenter() {
      let webcast = this.$route.params.slug;
      let ref = rtdb.ref(`/chat/${webcast}/presenter`).orderByKey();
      const listenRoom = this.roomType;

      if (this.messages.length > 0) {
        ref = ref.startAt(this.messages[this.messages.length - 1].id);
      }
      ref.on(
        "child_added",
        item => {
          const itemData = item.val();
          itemData.id = item.key;
          itemData.channel = 'presenter';

          if (this.messages.findIndex(v => v.id === itemData.id) == -1) {
            this.messages.push(itemData);
            if (this.$parent.activeTab != "chat") {
              this.$store.commit("room/newMessageAdded");
              this.presenterMessageCount++;
            }

            if(this.roomType != 'presenter'){
              this.presenterMessageCount++;
            }

            this.scrollToBottom();
          }
        },
        err => {
          console.log("----------", err);
        }
      );
    },
    sendMessage: async function() {
      try {
        let webcast = this.$route.params.slug;
        const ref = rtdb.ref(`/chat/${webcast}/${this.roomType}`);
        const { key } = ref.push();

        return ref
          .child(key)
          .update({
            from: auth().currentUser.uid,
            name: this.currentUser.name,
            message: this.message,
            timestamp: Date.now()
          })
          .then(() => {
            this.message = "";
          })
          .catch(err => {
            console.log("error", err);
          });
      } catch (error) {
        console.log(error);

        throw ErrorService.onFirebase("Error Sending Message", error);
      }
    },
    async infiniteHandler($state) {
      this.fetchOldMessages(function(messages) {
        if (messages.length) {
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    timeConverter(d) {
      return moment(d).format("HH:mm");
    },
    scrollListenInit() {
      const self = this;
      document
        .querySelector(".scroll-wrapper")
        .addEventListener("scroll", function(e) {
          if (
            e.target.scrollTop + 200 + e.target.clientHeight >
            e.target.scrollHeight
          ) {
            self.isBottom = true;
          } else {
            self.isBottom = false;
          }
        });
    },
    scrollToBottom() {
      if (this.isBottom) {
        setTimeout(function() {
          document.querySelector(
            ".scroll-wrapper"
          ).scrollTop = document.querySelector(".scroll-wrapper").scrollHeight;
        }, 400);
      }
    },
    onChange() {
      const self= this;
      self.isBottom = true;
      this.presenterMessageCount = 0;
      this.allMessageCount = 0;

      setTimeout(() => {
        self.scrollListenInit();
        self.scrollToBottom();
      }, 1);
    }
  }
};
</script>

<style>
.scroll-wrapper {
  height: calc(100vh - 169px);
  overflow-y: scroll;
}

.message-badge-chat {
  top: -5px!important;
  left: -10px!important;
}

/* width */
.scroll-wrapper::-webkit-scrollbar {
  width: 5px;
  border-radius: 2.5px;
}

/* Track */
.scroll-wrapper::-webkit-scrollbar-track {
  background: #201f1f;
  border-radius: 2.5px;
}

/* Handle */
.scroll-wrapper::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 2.5px;
}

/* Handle on hover */
.scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 2.5px;
}

.send-input {
  padding-top: 11px;
  padding-right: 8px;
}

.send-input input {
  width: calc(100% - 70px);
  height: 40px;
  border: 0px !important;
  border-radius: 5px 0px 0px 5px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.09);
  color: #ececec;
}

.send-input button {
  padding: 5px;
  width: 70px;
  height: 40px;
  border-radius: 0px 5px 5px 0px;
}
</style>
