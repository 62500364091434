/* eslint-disable no-unused-vars */
import _ from 'lodash';

const mutations = {
  setCurrent: (state, payload) => {
    state.current = payload;
  },
  connectionCreated: (state, payload) => {
    let { connections } = state;
    let connection = {
      ...JSON.parse(payload.connection.data),
      connection: payload.connection,
      hasAudio: false,
      hasVideo: false,
      isSpeaking: false,
      screenShared: false,
    };

    if (
      payload.connection.connectionId ===
      payload.session.connection.connectionId
    ) {
      connections.unshift(connection);
    } else {
      connections.push(connection);
    }

    state.connections = connections;
  },
  connectionDestroyed: (state, { connectionId }) => {
    let { connections } = state;

    connections = connections.filter(
      (obj) => obj.connection.connectionId !== connectionId,
    );

    state.connections = connections;
  },
  addStream: (state, payload) => {
    let { connections, streams } = state;

    connections.forEach((connection) => {
      if (connection.connection.connectionId === payload.connection.id) {
        Object.assign(connection, {
          hasAudio: payload.hasAudio,
          hasVideo: payload.hasVideo,
        });
      }
    });
    streams.push(payload);

    state.connections = connections;
    state.streams = streams;
  },
  removeStream: (state, payload) => {
    let { streams } = state;

    streams = streams.filter((obj) => obj.id !== payload.id);

    state.streams = streams;
  },
  onStreamPropertyChange: (state, { changedProperty, newValue, stream }) => {
    let { connections } = state;

    if (changedProperty === 'hasAudio') {
      connections.forEach((connection) => {
        if (connection.connection.connectionId === stream.connection.id) {
          connection.hasAudio = newValue;
        }
      });
    } else if (changedProperty === 'hasVideo') {
      connections.forEach((connection) => {
        if (connection.connection.connectionId === stream.connection.id) {
          connection.hasVideo = newValue;
        }
      });
    }

    state.connections = connections;
  },
  addVideo(state, payload) {
    console.log('add video');
    let { id } = payload;
    console.log(state.videoList);
    const video = _.find(state.videoList, { id: id });

    if (video) {
      console.log(video);
      Object.assign(video, payload);
    } else {
      console.log(payload);
      state.videoList.push(payload);
    }
  },
  removeVideo(state, payload) {
    console.log(payload);
  },
  setIsLive(state, payload) {
    console.log('isLive', payload);
    state.isLive = payload;
  },
  setIsRecording(state, payload) {
    console.log('isRecording', payload);
    state.isRecording = payload;
  },
  isWatching: (state, { isWatched, subscribeProps }) => {
    Object.assign(state, { isWatched, subscribeProps });
  },
  currentSpeaker: (state, payload) => {
    let { id } = payload;
    state.currentSpeaker = id;
  },
  screenId: (state, payload) => {
    state.screenId = payload;
  },
  changeLayout: (state, payload) => {
    state.layout = payload;
  },
  setIsSpeaking: (state, payload) => {
    let { connections } = state;

    connections.forEach((connection) => {
      if (connection.connection.connectionId === payload.connectionId) {
        connection.isSpeaking = payload.isSpeaking;
      }
    });

    state.connections = connections;
  },
  setScreenShared: (state, payload) => {
    let { connections } = state;

    connections.forEach((connection) => {
      if (connection.connection.connectionId === payload.connectionId) {
        connection.screenShared = payload.screenShared;
      }
    });

    state.connections = connections;
  },
  endSession(state) {
    if (state.current.currentState !== 'disconnected') {
      state.current.removeEventListener('connectionCreated');
      state.current.removeEventListener('connectionDestroyed');
      state.current.removeEventListener('streamCreated');
      state.current.removeEventListener('streamDestroyed');
      state.current.removeEventListener('signal:audio');
      state.current.removeEventListener('signal:video');
      state.current.removeEventListener('streamPropertyChanged');
      state.current.removeEventListener('signal:user');
      state.current.removeEventListener('signal:layout');
      state.current.removeEventListener('signal:golive');
      state.current.disconnect();
    }
  },
  setPageIndex: (state, payload) => {
    state.pageIndex = payload;
  },
  setNewMessageCount: (state, payload) => {
    state.newMessages = payload;
  },
  newMessageAdded: (state, payload) => {
    state.newMessages++;
  },
  exit: (state, payload) => {
    state.exit = payload;
  },
  participantRoleChange: (state, payload) => {
    const { current, connections } = state;
    const data = JSON.parse(payload);

    const index = _.findIndex(
      connections,
      (e) => {
        if (e._id === data._id) {
          return e;
        }
      },
      0,
    );

    if (index !== -1 && connections[index].connection.id !== current.id) {
      state.connections[index].userRole = data.role;
    }
  },
};

export { mutations };
